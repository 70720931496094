/* eslint-disable react/display-name */
import './style.scss'

// import ListProduct from '@/components/Common/Item/Product'
import ProductCard from '@/components/Common/Item/ProductCard'

// - NFT Marketplace: bấm link bay qua trang tương ứng
// - Crypto Exchange (CEX): bấm link bay qua trang tương ứng
// - DEX: bấm link bay qua trang tương ứng
// - Crypto Wallet: bấm link bay qua trang tương ứng

const projects = [
  {
    id: 2,
    name: 'NFT Marketplace',
    img: '/img/product/nft-marketplace.png',
    video: '',
    descriptions: [
      'User can connect metamask to buy, sell famous anime pictures.',
    ],
    link: 'https://www.vvvvv.my/ja/',
    slug: 'nft-marketplace',
  },
  {
    id: 3,
    name: 'Crypto Exchange (CEX)',
    img: '/img/product/crypto-exchange.jpeg',
    video: '',
    descriptions: [
      'Users can experience with 12 kinds of cryptocurrency to exchange, or they are also able to access the buying and selling function to trade with ease.',
    ],
    link: 'https://exchange-sgp.com/',
    slug: 'cex',
  },
  {
    id: 4,
    name: 'DEX',
    img: '/img/product/dex.png',
    video: '/video/product/bemo.webm',
    descriptions: [
      'DEX cung cấp bộ giải pháp công nghệ toàn diện hỗ trợ cho quá trình chuyển đổi số và sự phát triển bền vững của doanh nghiệp.',
    ],
    link: 'https://exchange-sgp.com/',
    slug: 'dex',
  },
  {
    id: 5,
    name: 'Crypto Wallet',
    img: '/img/product/bap-coin-1.jpeg',
    video: '/video/product/bemo.webm',
    descriptions: [
      'For this app, users can experience with 12 kinds of cryptocurrency to exchange, or they are also able to access the buying and selling function to trade with ease.',
    ],
    link: 'https://bemo-cloud.com/',
    slug: 'crypto-wallet',
  },
]

const BlockchainSection = () => {
  return (
    <section
      className="bg-semiWhite bg-auto bg-no-repeat bg-center bg-blockchain_bg pt-[62px] h-[854px]"
      id="blockchain-service"
    >
      {/* ===== Container ==== */}
      <div className="">
        <h2 className="text-white text-center font-bold text-3xl uppercase mb-[40px] pt-[40px]">
          02 Blockchain Technology
        </h2>
        {/* ===== Content ==== */}
        <div className="flex-row justify-center items-center">
          <div className="flex justify-center items-center">
            <p className="text-white max-w-[740px] text-center leading-6">
              BAP stands as the sole outsourcing company listed in the Fintech
              Vietnam ecosystem, pioneering software and application/website
              development through blockchain technology in the country.
            </p>
          </div>
          {/* ===== List AI Product ==== */}
          <div className="flex justify-around items-center">
            {projects.map((item) => (
              <ProductCard key={item.id} data={item} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockchainSection
