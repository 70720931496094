import './style.scss'

import React from 'react'
import { useLocation } from 'react-router-dom'

import ButtonGoToTop from '@/components/Common/Item/ButtonGoToTop'
import ChatBox from '@/components/Common/Item/ChatBox'
import Contact from '@/components/Common/Pages/Contact'
import useScrollDefault from '@/components/CustomHook/useScrollDefault'

import Footer from './FooterV2'
import Header from './Header'

interface Props {
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
  const location = useLocation()
  const path = location.pathname.substring(1)
  useScrollDefault(path)
  return (
    <div className="wrapper">
      <Header />

      {children}

      <Contact />
      <Footer />
      <ButtonGoToTop />
      <ChatBox />
    </div>
  )
}

export default Layout
