import { FC } from 'react'

interface Props {
  width: number
  height: number
  className?: string
}

const IconMail: FC<Props> = ({ width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      id="mail"
      className={className}
    >
      <path
        fill="#dfdfdf"
        fillRule="evenodd"
        d="M2 7a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7Zm2 1.18V16a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V8.18l-8 5-8-5ZM18.113 7H5.887L12 10.82 18.113 7Z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default IconMail
