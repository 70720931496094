import { FC } from 'react'

const LogAIOCR: FC = () => {
  return (
    <svg
      width="221"
      height="186"
      viewBox="0 0 221 186"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <g filter="url(#filter0_f_137_1206)">
          <rect
            x="34"
            y="34"
            width="153"
            height="115"
            rx="18"
            fill="url(#paint0_linear_137_1206)"
          />
        </g>
        <rect
          x="6"
          y="26"
          width="138.62"
          height="110.896"
          rx="20"
          fill="url(#paint1_linear_137_1206)"
        />
        <g opacity="0.5" filter="url(#filter1_di_137_1206)">
          <path
            d="M128.96 122.969L20.8987 122.969C17.6025 122.969 15.721 119.206 17.6987 116.569L46.5193 78.1418C47.9798 76.1944 50.8265 75.9921 52.5477 77.7134L82.62 107.786C84.2676 109.433 86.9691 109.329 88.4855 107.56L103.43 90.1246C105.027 88.2622 107.908 88.2622 109.504 90.1246L131.997 116.366C134.221 118.961 132.378 122.969 128.96 122.969Z"
            fill="#78ABFF"
          />
        </g>
        <g opacity="0.5" filter="url(#filter2_di_137_1206)">
          <rect
            x="82.2073"
            y="39.7979"
            width="34.6551"
            height="34.6551"
            rx="17.3276"
            fill="#78ABFF"
          />
        </g>
        <g filter="url(#filter3_bd_137_1206)">
          <rect
            x="26.1123"
            y="49.1855"
            width="160.75"
            height="128.6"
            rx="20"
            fill="url(#paint2_linear_137_1206)"
            shapeRendering="crispEdges"
          />
          <rect
            x="29.1123"
            y="52.1855"
            width="154.75"
            height="122.6"
            rx="17"
            stroke="url(#paint3_linear_137_1206)"
            strokeWidth="6"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter4_di_137_1206)">
          <path
            d="M170.129 161.712L42.1509 161.712C38.8547 161.712 36.9732 157.949 38.9509 155.312L73.5805 109.139C75.041 107.191 77.8877 106.989 79.6089 108.71L115.49 144.592C117.138 146.239 119.839 146.136 121.356 144.366L139.62 123.058C141.216 121.196 144.098 121.196 145.694 123.058L173.166 155.108C175.39 157.703 173.546 161.712 170.129 161.712Z"
            fill="url(#paint4_linear_137_1206)"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter5_di_137_1206)">
          <rect
            x="114.524"
            y="65.2627"
            width="40.1875"
            height="40.1875"
            rx="20.0937"
            fill="url(#paint5_linear_137_1206)"
            shapeRendering="crispEdges"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_137_1206"
          x="0"
          y="0"
          width="221"
          height="183"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_137_1206"
          />
        </filter>
        <filter
          id="filter1_di_137_1206"
          x="15.3914"
          y="76.042"
          width="119.077"
          height="50.9277"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_137_1206"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_137_1206"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_137_1206"
          />
        </filter>
        <filter
          id="filter2_di_137_1206"
          x="80.7073"
          y="39.2979"
          width="37.6552"
          height="39.1553"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_137_1206"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_137_1206"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_137_1206"
          />
        </filter>
        <filter
          id="filter3_bd_137_1206"
          x="22.1123"
          y="45.1855"
          width="168.75"
          height="140.6"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_137_1206"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_137_1206"
            result="effect2_dropShadow_137_1206"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_137_1206"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_di_137_1206"
          x="36.6436"
          y="107.039"
          width="138.993"
          height="58.6729"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_137_1206"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_137_1206"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_137_1206"
          />
        </filter>
        <filter
          id="filter5_di_137_1206"
          x="113.024"
          y="64.7627"
          width="43.1875"
          height="44.6875"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_137_1206"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_137_1206"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_137_1206"
          />
        </filter>
        <linearGradient
          id="paint0_linear_137_1206"
          x1="-9.82156"
          y1="41.1589"
          x2="12.4541"
          y2="177.515"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92BBFF" />
          <stop offset="0.41" stopColor="#5B99FF" />
          <stop offset="0.7" stopColor="#4A81FF" />
          <stop offset="1" stopColor="#7DAEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_137_1206"
          x1="-33.703"
          y1="32.9034"
          x2="-10.9187"
          y2="163.941"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92BBFF" />
          <stop offset="0.41" stopColor="#5B99FF" />
          <stop offset="0.7" stopColor="#4A81FF" />
          <stop offset="1" stopColor="#7DAEFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_137_1206"
          x1="1.99984"
          y1="190.645"
          x2="147.703"
          y2="16.4346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_137_1206"
          x1="170.787"
          y1="190.645"
          x2="62.0666"
          y2="28.3195"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_137_1206"
          x1="12.4497"
          y1="167.338"
          x2="57.6425"
          y2="56.1803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_137_1206"
          x1="108.496"
          y1="109.469"
          x2="154.711"
          y2="65.2627"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LogAIOCR
