// eslint-disable-next-line simple-import-sort/imports
import { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

// import { MainLayout } from '@/components/Common/Layout'
import Blog from '@/components/Common/Pages/Blog'
import Top from '@/components/Common/Pages/Top'
import BlogDetail from '@/components/Common/Pages/BlogDetail'
import ProjectDetail from '@/components/Common/Pages/ProjectDetail'
import WhisperV3 from '@/components/Common/Pages/ProjectDetail/WhisperV3/WhisperV3'
import OCR from '@/components/Common/Pages/ProjectDetail/OCR'
import BKYC from '@/components/Common/Pages/ProjectDetail/BKYC'
import BEMO from '@/components/Common/Pages/ProjectDetail/Bemo'

function PublicRoute() {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route element={<Top />} path="/" />
        <Route element={<Top />} path="/about-us" />
        <Route element={<Top />} path="/contact" />

        <Route element={<Blog />} path="/blog" />
        <Route element={<BlogDetail />} path="/blog/:blogSlug" />
        <Route element={<WhisperV3 />} path="/bap-whisper-large-v3" />
        <Route element={<OCR />} path="/bap-ocr" />
        <Route element={<BKYC />} path="/BKYC" />
        <Route element={<BEMO />} path="/bemo-cloud" />
        <Route element={<ProjectDetail />} path="/product/:slug" />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  )
}

export default PublicRoute
