/* eslint-disable sonarjs/no-duplicate-string */
import './style.scss'

import React from 'react'

import Layout from '@/components/Common/Layout/MainLayout/Layout'

import bemoLogoImg from '../../../../../assets/img/bemo/bemo-logo.png'
// import item01 from '../../../../../assets/img/speech2text/artboard_ai_01.png'
// import item02 from '../../../../../assets/img/speech2text/artboard_micro_02.png'
// import item03 from '../../../../../assets/img/speech2text/artboard_timer_03.png'
const services = [
  {
    title: 'Human Resources Management',
    desc: `Comprehensive human resources development`,
    img: 'https://bemo-cloud.com/wp-content/uploads/2022/06/service-hrm.png',
    features: [
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/recruitment-1.png',
        title: 'Recruitment Management',
        desc: 'Building a smart recruitment process',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/attendances.png',
        title: 'Timekeeping Management',
        desc: 'Saving time for timekeeping',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/payroll-1.png',
        title: 'Training',
        desc: 'Developing a powerful team',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/employees.png',
        title: 'Human Resource Information Management',
        desc: 'A talking data set about human resource management.',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/payroll-1.png',
        title: 'Human Resource Information Management',
        desc: 'A talking data set about human resource management.',
      },
    ],
  },
  {
    title: 'Accounting Management',
    desc: `Elevate accounting in the digital era.`,
    img: 'https://bemo-cloud.com/wp-content/uploads/2022/06/service-accounting.png',
    features: [
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/payroll-1.png',
        title: 'Comprehensive accounting services',
        desc: 'Comprehensive, outstanding accounting',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/attendances.png',
        title: 'Automatic multi-dimensional reporting',
        desc: 'Automated reports with multi-dimensional analysis',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/recruitment-1.png',
        title: 'Total management solution',
        desc: 'Scalability and integration of the total management solution',
      },
    ],
  },
  {
    title: 'Task Management',
    desc: 'Assessment objectively and increase work productivity',
    img: 'https://bemo-cloud.com/wp-content/uploads/2022/07/quan-ly-du-an-00022.png',
    features: [
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/crm-1.png',
        title: 'Project and Task Management',
        desc: 'Flexible and smart tracking',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/attendances.png',
        title: 'Report',
        desc: 'Easy management, enhance performance',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/payroll-1.png',
        title: 'Resource allocation management',
        desc: 'The big picture helping optimize the employee resource allocation',
      },
    ],
  },
  {
    title: 'Asset Management',
    desc: 'Cost and usage optimization',
    img: 'https://bemo-cloud.com/wp-content/uploads/2022/07/quan-ly-thiet-bi-0002-1.png',
    features: [
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/crm-1.png',
        title: 'An effective tool for administrators',
        desc: 'sset management in the digital transformation revolution',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/payroll-1.png',
        title: 'Smart management solution',
        desc: 'Asset management according to standard and complete process',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/employees.png',
        title: 'Employee benefits',
        desc: 'Support to improve performance',
      },
    ],
  },
  {
    title: 'Sales Management',
    desc: 'Smart solutions for sales management',
    img: 'https://bemo-cloud.com/wp-content/uploads/2022/06/service-crm.png',
    features: [
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/sales.png',
        title: 'Order management',
        desc: 'Smart management processes for businesses',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/warehouse.png',
        title: 'Inventory management',
        desc: 'Efficient inventory management',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/purchase.png',
        title: 'Purchasing Management',
        desc: 'Optimizing purchasing processes',
      },
    ],
  },
  {
    title: 'CRM',
    desc: 'Understanding customers, business development',
    img: 'https://bemo-cloud.com/wp-content/uploads/2022/07/crm-0001.png',
    features: [
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/warehouse.png',
        title: 'Intelligent customer database',
        desc: 'Comprehensive know your customer',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/employees.png',
        title: 'Business management and support',
        desc: 'Elevate the team, increase sales',
      },
    ],
  },
  {
    title: 'Operation Features',
    desc: 'Enterprise Resource Management',
    img: 'https://bemo-cloud.com/wp-content/uploads/2022/06/service-operate.png',
    features: [
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/car.png',
        title: 'Vehicle Management',
        desc: 'Examine the company resources usage efficiently',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/calendar.png',
        title: 'Management of meeting rooms',
        desc: 'Improve the quality of the meeting',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/events-1.png',
        title: 'Event Management',
        desc: 'Easily internal communication',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/discuss.png',
        title: 'Discussion',
        desc: 'Easily internal communication',
      },
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/food.png',
        title: 'Meal Management',
        desc: 'Easily manage vendors, order meals conveniently',
      },
    ],
  },
  {
    title: 'Financial Management',
    desc: 'Maximize corporate profits',
    img: 'https://bemo-cloud.com/wp-content/uploads/2022/07/finnace2.png',
    features: [
      {
        img: 'https://bemo-cloud.com/wp-content/uploads/2021/05/recruitment-1.png',
        title: 'Is coming soon',
        desc: '',
      },
    ],
  },
]

const BEMO: React.FC = () => {
  return (
    <Layout>
      {/* --- hero-featured --- */}
      <section className="hero-featured bg-[#081f36]">
        {/* --- main container --- */}
        {/* : 1140px; */}
        <div className="mx-auto flex justify-around max-w-6xl items-center">
          <div className="container px-8 mx-auto flex flex-wrap justify-evenly gap-4">
            <div className="hero-featured__main relative">
              {/* --- main title --- */}
              <h1 className="text-2xl md:text-[2rem] text-white font-bold mb-2 max-w-[470px] leading-8 md:leading-10">
                DIGITAL TRANSFORMATION SOLUTION FOR ENTERPRISES
              </h1>
              <p className="text-white w-[70%] leading-7">
                Bemo Cloud provides a comprehensive technology solutions to
                support the digital transformation and sustainable development
                of enterprises.
              </p>
              <div className="hero-featured__btns">
                <a
                  className="btn"
                  href="https://bemo-cloud.com"
                  download=""
                  target="_blank"
                  rel="noreferrer"
                >
                  Get Started
                </a>
                <a
                  href="https://bemo-cloud.com/lien-he"
                  className="btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact
                </a>
              </div>
            </div>
          </div>
          <div className="w-full min-w-[280px]">
            <img src={bemoLogoImg} alt="Bemo Logo" />
          </div>
        </div>
        {/* --- featured__pattern --- */}
        {/* <div className="absolute top-0 left-0 w-full h-full">
          <div className="hero-featured__overlay"></div>
          <div className="absolute bottom-0 left-0 bg-vecter1 bg-cover bg-no-repeat w-full h-full translate-x-[-50%]"></div>
          <div className="absolute bottom-0 right-0 bg-effect01 bg-cover bg-no-repeat w-[547px] h-[545px]"></div>
          <div className="hero-featured__vector3"></div>
        </div> */}
      </section>
      <section className="bg-white m-auto px-8 py-16 md:px-10 lg:px-10 flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-10">
          <div className="flex flex-col justify-start items-center">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">
              WHY BEMO?
            </h2>
            <p className="md:text-lg text-[#636E80] text-center leading-6 md:leading-7 max-w-[887px] mb-[40px]">
              Digital Bemo Cloud is honored to be one of the 40 companies
              providing transformation solutions for enterprises by the Ministry
              of Planning and Investment and United States Agency for
              International Development (USAID).
            </p>
          </div>
          {/* --- videos --- */}
          <div className="mb-[80px]">
            <div className="relative flex justify-center items-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/GscPuFuOAC8?si=28QOh4KolAgnqQQr"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>

        {/* ---WhyAI Items --- */}
        <div className="flex flex-col justify-between items-center w-full gap-10">
          {/* --- item 1 --- */}
          {services.map((item, index) => (
            <div
              key={index}
              className="flex flex-col max-w-[1140px] w-[70%] bg-white"
            >
              <div
                className={`flex gap-4 p-6
                border-2 rounded-t-[20px] border-[#dbebfa] border-solid

                ${index % 2 ? 'flex-row-reverse' : ''} bg-[#dbebfa]`}
              >
                {/* ---item image -- */}
                <div className="md:max-w-[320px] md:max-h-[320px] min-w-[220px] order-1">
                  <img
                    src={item.img}
                    alt="Why AI Item feature Image"
                    className="w-full h-full object-contain"
                  ></img>
                </div>
                <div className="order-2">
                  <h3 className="text-bold text-2xl md:text-2xl mb-2">
                    {item.title}
                  </h3>
                  <p className="text-normal text-[#636E80] leading-7">
                    {item.desc}
                  </p>
                </div>
              </div>
              <div
                className={`flex justify-between items-center bg-slate-20 gap-8
                border-2 border-[#dbebfa] border-solid
                ${index % 2 ? 'flex-row-reverse' : ''}`}
              >
                {/* --- item title & desc --- */}
                <div className="grid grid-cols-2 gap-x-8 gap-y-6 border-solid bg-white p-8">
                  {item.features?.map((feat, index) => (
                    <div
                      className="flex justify-start items-center gap-1 text-[#636E80]
                           "
                      key={index}
                    >
                      <img src={feat.img} />
                      <div className="text-sm">
                        <p className="font-semibold">{feat.title}</p>
                        <span>{feat.desc}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default BEMO
