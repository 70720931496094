import { useRef, useState } from 'react'

interface UseAudioRecorderType {
  permission: boolean
  stream: MediaStream | null
  recordingStatus: 'inactive' | 'recording'
  audio: string | null
  audioChunks: Blob[]
  getMicrophonePermission: () => Promise<void>
  startRecording: () => void
  stopRecording: () => void
  clearRecording: () => void
}

const useAudioRecorder = (mimeType = 'audio/webm'): UseAudioRecorderType => {
  const [permission, setPermission] = useState<boolean>(false)
  const [stream, setStream] = useState<MediaStream | null>(null)
  const mediaRecorder = useRef<MediaRecorder | null>(null)
  const [recordingStatus, setRecordingStatus] = useState<
    'inactive' | 'recording'
  >('inactive')
  const [audioChunks, setAudioChunks] = useState<Blob[]>([])
  const [audio, setAudio] = useState<string | null>(null)

  const getMicrophonePermission = async () => {
    if ('MediaRecorder' in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        })
        setPermission(true)
        setStream(streamData)
      } catch (err: any) {
        alert(err.message)
      }
    } else {
      alert('The MediaRecorder API is not supported in your browser.')
    }
  }

  const startRecording = () => {
    if (stream) {
      setRecordingStatus('recording')
      setAudio(null)
      setAudioChunks([])

      const media = new MediaRecorder(stream, { mimeType })
      mediaRecorder.current = media
      mediaRecorder.current.start()
      const localAudioChunks: Blob[] = []

      mediaRecorder.current.ondataavailable = (event: BlobEvent) => {
        if (event.data && event.data.size > 0) {
          localAudioChunks.push(event.data)
        }
      }

      setAudioChunks(localAudioChunks)
    }
  }

  const stopRecording = () => {
    if (mediaRecorder.current) {
      setRecordingStatus('inactive')
      mediaRecorder.current.stop()

      mediaRecorder.current.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: mimeType })
        const audioUrl = URL.createObjectURL(audioBlob)
        setAudio(audioUrl)
        console.log('stopRecording audioChunks', audioChunks)
        console.log('stopRecording audioUrl', audioUrl)
        // setAudioChunks([])
      }
    }
  }

  const clearRecording = () => {
    setRecordingStatus('inactive')
    setAudio(null)
    setAudioChunks([])
  }

  return {
    permission,
    stream,
    recordingStatus,
    audio,
    audioChunks,
    getMicrophonePermission,
    startRecording,
    stopRecording,
    clearRecording,
  }
}

export default useAudioRecorder
