import { FC } from 'react'

interface Props {
  width: number
  height: number
  color?: string
}

const IconRecord: FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4151 13.4155C18.4151 13.0248 18.0983 12.708 17.7076 12.708C17.3168 12.708 17.0001 13.0248 17.0001 13.4155C17.0001 16.1465 14.757 18.3684 12 18.3684C9.24299 18.3684 7.00002 16.1466 7.00002 13.4155C7.00002 13.0248 6.68324 12.708 6.29249 12.708C5.90174 12.708 5.58496 13.0248 5.58496 13.4155C5.58496 16.7314 8.14701 19.3992 11.2925 19.7444V21.1986H9.12271C8.73196 21.1986 8.41518 21.5154 8.41518 21.9061C8.41518 22.2969 8.73196 22.6136 9.12271 22.6136H14.8775C15.2682 22.6136 15.585 22.2969 15.585 21.9061C15.585 21.5154 15.2682 21.1986 14.8775 21.1986H12.7077V19.7444C15.8546 19.3991 18.4151 16.7298 18.4151 13.4155Z"
        fill={color || '#636E80'}
      />
      <path
        d="M11.9999 1.38672C10.0441 1.38672 8.41504 2.96856 8.41504 4.92447V13.4151C8.41504 15.3709 10.044 16.9528 11.9999 16.9528C13.9558 16.9528 15.5849 15.371 15.5849 13.4151V4.92447C15.5849 2.96866 13.9559 1.38672 11.9999 1.38672ZM14.1697 13.4151C14.1697 14.5657 13.1761 15.5377 11.9999 15.5377C10.8238 15.5377 9.83015 14.5657 9.83015 13.4151V4.92447C9.83015 3.77383 10.8238 2.80183 11.9999 2.80183C13.1761 2.80183 14.1697 3.77387 14.1697 4.92447V13.4151Z"
        fill={color || '#636E80'}
      />
      <path
        d="M21.7205 2.45918C21.4443 2.1829 20.9962 2.18285 20.7199 2.45918C20.4436 2.73551 20.4436 3.18349 20.7199 3.45982C21.9227 4.66254 22.5851 6.26168 22.5851 7.96263C22.5851 9.66359 21.9227 11.2627 20.7199 12.4655C20.4436 12.7418 20.4436 13.1898 20.7199 13.4661C20.9962 13.7425 21.4443 13.7425 21.7205 13.4661C23.1906 11.996 24.0002 10.0416 24.0002 7.96263C24.0002 5.88368 23.1906 3.92918 21.7205 2.45918Z"
        fill={color || '#636E80'}
      />
      <path
        d="M19.7176 4.46112C19.4413 4.18484 18.9933 4.18484 18.717 4.46112C18.4407 4.73745 18.4407 5.18543 18.717 5.46171C19.3861 6.13076 19.7545 7.01871 19.7545 7.96193C19.7545 8.90515 19.3861 9.79305 18.717 10.4621C18.4407 10.7386 18.4407 11.1866 18.717 11.4629C18.9933 11.7392 19.4413 11.7392 19.7176 11.4629C20.654 10.5265 21.1697 9.28324 21.1697 7.96202C21.1696 6.64076 20.6539 5.39749 19.7176 4.46112Z"
        fill={color || '#636E80'}
      />
      <path
        d="M17.7171 6.46206C17.4408 6.18578 16.9928 6.18583 16.7165 6.46216C16.4401 6.73849 16.4402 7.18647 16.7166 7.46275C16.8505 7.59672 16.9243 7.77419 16.9243 7.96249C16.9243 8.15078 16.8505 8.3283 16.7165 8.46227C16.4402 8.7386 16.4402 9.18658 16.7165 9.46286C16.9929 9.73928 17.4409 9.73914 17.7171 9.46291C18.1184 9.06166 18.3394 8.52878 18.3394 7.96249C18.3394 7.39619 18.1183 6.86331 17.7171 6.46206Z"
        fill={color || '#636E80'}
      />
      <path
        d="M1.41511 7.96263C1.41511 6.26168 2.07745 4.66259 3.28027 3.45982C3.55659 3.18349 3.55659 2.73551 3.28027 2.45918C3.00394 2.1829 2.55595 2.18285 2.27967 2.45918C0.809578 3.92918 0 5.88368 0 7.96263C0 10.0415 0.809578 11.9961 2.27963 13.4661C2.55591 13.7424 3.00398 13.7424 3.28022 13.4661C3.55655 13.1898 3.55655 12.7418 3.28022 12.4654C2.07745 11.2628 1.41511 9.66359 1.41511 7.96263Z"
        fill={color || '#636E80'}
      />
      <path
        d="M4.24519 7.96196C4.24519 7.01875 4.61367 6.13084 5.28272 5.46175C5.55905 5.18542 5.55905 4.73743 5.28272 4.46115C5.00644 4.18482 4.55841 4.18482 4.28212 4.46115C3.34575 5.39748 2.83008 6.64079 2.83008 7.96196C2.83008 9.28314 3.34575 10.5264 4.28212 11.4628C4.55841 11.7391 5.00648 11.7391 5.28272 11.4628C5.55905 11.1864 5.55905 10.7385 5.28272 10.4622C4.61372 9.79314 4.24519 8.90518 4.24519 7.96196Z"
        fill={color || '#636E80'}
      />
      <path
        d="M7.2835 8.46235C7.14954 8.32834 7.07571 8.15082 7.07571 7.96253C7.07571 7.77423 7.14949 7.59676 7.28346 7.46275C7.55983 7.18646 7.55983 6.73843 7.2835 6.46215C7.00722 6.18582 6.55924 6.18578 6.28291 6.46211C5.88161 6.8634 5.66064 7.39623 5.66064 7.96253C5.66064 8.52882 5.88161 9.0617 6.28291 9.46295C6.55919 9.73928 7.00727 9.73928 7.2835 9.46295C7.55983 9.18667 7.55983 8.73868 7.2835 8.46235Z"
        fill={color || '#636E80'}
      />
    </svg>
  )
}

export default IconRecord
