import { FC } from 'react'

interface MySVGProps {
  width?: number
  height?: number
}

const LogoBapKYC: FC<MySVGProps> = ({ width, height }) => {
  return (
    <svg
      width={width || '169'}
      height={height || '155'}
      viewBox="0 0 169 155"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <g filter="url(#filter0_f_159_544)">
          <path
            d="M134.038 113.413C134.041 113.413 134.043 113.411 134.042 113.408C130.902 96.2408 116.65 83.2852 99.5346 83.2852C82.4174 83.2852 68.165 96.2433 65.026 113.413C64.3453 117.118 67.1085 120.555 70.664 120.555H128.395C131.959 120.555 134.711 117.132 134.034 113.418C134.034 113.416 134.036 113.413 134.038 113.413Z"
            fill="url(#paint0_linear_159_544)"
          />
          <path
            d="M99.5307 72.6376C109.822 72.6376 118.165 64.2946 118.165 54.0029C118.165 43.7112 109.822 35.3682 99.5307 35.3682C89.239 35.3682 80.896 43.7112 80.896 54.0029C80.896 64.2946 89.239 72.6376 99.5307 72.6376Z"
            fill="url(#paint1_linear_159_544)"
          />
        </g>
        <path
          d="M134.038 113.413C134.041 113.413 134.043 113.411 134.042 113.408C130.902 96.2408 116.65 83.2852 99.5346 83.2852C82.4174 83.2852 68.165 96.2433 65.026 113.413C64.3453 117.118 67.1085 120.555 70.664 120.555H128.395C131.959 120.555 134.711 117.132 134.034 113.418C134.034 113.416 134.036 113.413 134.038 113.413Z"
          fill="url(#paint2_linear_159_544)"
        />
        <path
          d="M99.5307 72.6376C109.822 72.6376 118.165 64.2946 118.165 54.0029C118.165 43.7112 109.822 35.3682 99.5307 35.3682C89.239 35.3682 80.896 43.7112 80.896 54.0029C80.896 64.2946 89.239 72.6376 99.5307 72.6376Z"
          fill="url(#paint3_linear_159_544)"
        />
        <g filter="url(#filter1_bd_159_544)">
          <g filter="url(#filter2_bd_159_544)">
            <path
              d="M121.758 117.604C121.761 117.604 121.764 117.601 121.763 117.598C117.75 96.9147 99.544 81.3057 77.6787 81.3057C55.8112 81.3057 37.6034 96.9179 33.5933 117.604C32.7238 122.069 36.2537 126.209 40.7959 126.209H114.549C119.102 126.209 122.617 122.086 121.752 117.611C121.752 117.608 121.754 117.604 121.758 117.604Z"
              fill="url(#paint4_linear_159_544)"
              shapeRendering="crispEdges"
            />
            <path
              d="M36.538 118.178L36.5385 118.175C40.2802 98.8734 57.2742 84.3057 77.6787 84.3057C97.9919 84.3057 114.925 98.7432 118.768 117.916C118.777 118.004 118.79 118.092 118.807 118.18C119.302 120.741 117.281 123.209 114.549 123.209H40.7959C38.0731 123.209 36.042 120.724 36.538 118.178Z"
              stroke="url(#paint5_linear_159_544)"
              strokeWidth="6"
              shapeRendering="crispEdges"
            />
          </g>
          <g filter="url(#filter3_bd_159_544)">
            <path
              d="M77.6776 70.7595C89.9913 70.7595 99.9734 60.7774 99.9734 48.4638C99.9734 36.1501 89.9913 26.168 77.6776 26.168C65.364 26.168 55.3818 36.1501 55.3818 48.4638C55.3818 60.7774 65.364 70.7595 77.6776 70.7595Z"
              fill="url(#paint6_linear_159_544)"
              shapeRendering="crispEdges"
            />
            <path
              d="M96.9734 48.4638C96.9734 59.1205 88.3344 67.7595 77.6776 67.7595C67.0209 67.7595 58.3818 59.1205 58.3818 48.4638C58.3818 37.807 67.0209 29.168 77.6776 29.168C88.3344 29.168 96.9734 37.807 96.9734 48.4638Z"
              stroke="url(#paint7_linear_159_544)"
              strokeWidth="6"
              shapeRendering="crispEdges"
            />
          </g>
        </g>
        <g filter="url(#filter4_di_159_544)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M58.6248 7.26984C58.6371 10.7449 55.8301 13.5721 52.355 13.5844C38.4263 13.634 32.6062 14.1376 28.3216 16.3207C23.5858 18.7337 19.7354 22.5842 17.3224 27.32C15.1393 31.6046 14.6357 37.4247 14.5861 51.3534C14.5737 54.8284 11.7466 57.6355 8.27151 57.6231C4.79642 57.6107 1.98933 54.7836 2.0017 51.3086C2.04843 38.1797 2.30425 29.075 6.10955 21.6067C9.72912 14.503 15.5046 8.72745 22.6084 5.10789C30.0767 1.30259 39.1813 1.04677 52.3102 1.00004C55.7853 0.987672 58.6124 3.79476 58.6248 7.26984ZM96.3784 7.26984C96.391 3.79476 99.2181 0.987672 102.693 1.00004C115.822 1.04677 124.926 1.3026 132.395 5.10789C139.499 8.72745 145.274 14.503 148.893 21.6067C152.699 29.075 152.954 38.1797 153.002 51.3086C153.014 54.7836 150.207 57.6108 146.731 57.6231C143.257 57.6355 140.429 54.8284 140.417 51.3534C140.367 37.4247 139.864 31.6046 137.681 27.32C135.268 22.5842 131.417 18.7337 126.681 16.3207C122.397 14.1376 116.577 13.634 102.648 13.5844C99.1728 13.5721 96.3658 10.7449 96.3784 7.26984ZM8.27375 94.8652C11.7489 94.8551 14.5743 97.6639 14.5845 101.139C14.6264 115.422 15.1069 121.332 17.3224 125.68C19.7354 130.416 23.5858 134.266 28.3216 136.679C32.6062 138.862 38.4263 139.366 52.355 139.415C55.8301 139.427 58.6371 142.255 58.6248 145.73C58.6124 149.205 55.7853 152.012 52.3102 152C39.1813 151.953 30.0767 151.697 22.6084 147.892C15.5046 144.272 9.72912 138.497 6.10955 131.393C2.26163 123.841 2.03947 114.599 2.00003 101.176C1.98982 97.7004 4.79866 94.8752 8.27375 94.8652ZM146.73 94.8652C150.205 94.8752 153.014 97.7004 153.003 101.176C152.964 114.599 152.742 123.841 148.893 131.393C145.274 138.497 139.499 144.272 132.395 147.892C124.926 151.697 115.822 151.953 102.694 152C99.2187 152.012 96.3916 149.205 96.379 145.73C96.3671 142.255 99.174 139.427 102.649 139.415C116.577 139.366 122.397 138.862 126.681 136.679C131.417 134.266 135.268 130.416 137.681 125.68C139.896 121.332 140.377 115.422 140.418 101.139C140.429 97.6639 143.254 94.8551 146.73 94.8652Z"
            fill="url(#paint8_linear_159_544)"
            shapeRendering="crispEdges"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_159_544"
          x="30.9224"
          y="1.36816"
          width="137.215"
          height="153.187"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_159_544"
          />
        </filter>
        <filter
          id="filter1_bd_159_544"
          x="29.4609"
          y="22.168"
          width="96.4229"
          height="112.041"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_159_544"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_159_544"
            result="effect2_dropShadow_159_544"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_159_544"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_bd_159_544"
          x="29.4609"
          y="77.3057"
          width="96.4229"
          height="56.9033"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_159_544"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_159_544"
            result="effect2_dropShadow_159_544"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_159_544"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_bd_159_544"
          x="51.3818"
          y="22.168"
          width="52.5918"
          height="56.5918"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_159_544"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_159_544"
            result="effect2_dropShadow_159_544"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_159_544"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_di_159_544"
          x="0.5"
          y="0.5"
          width="154.003"
          height="155.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_544"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_159_544"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_159_544"
          />
        </filter>
        <linearGradient
          id="paint0_linear_159_544"
          x1="45.0982"
          y1="85.6052"
          x2="50.3362"
          y2="130.362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92BBFF" />
          <stop offset="0.41" stopColor="#5B99FF" />
          <stop offset="0.7" stopColor="#4A81FF" />
          <stop offset="1" stopColor="#7DAEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_159_544"
          x1="70.2215"
          y1="37.6882"
          x2="79.6376"
          y2="81.0115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92BBFF" />
          <stop offset="0.41" stopColor="#5B99FF" />
          <stop offset="0.7" stopColor="#4A81FF" />
          <stop offset="1" stopColor="#7DAEFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_159_544"
          x1="45.0982"
          y1="85.6052"
          x2="50.3362"
          y2="130.362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92BBFF" />
          <stop offset="0.41" stopColor="#5B99FF" />
          <stop offset="0.7" stopColor="#4A81FF" />
          <stop offset="1" stopColor="#7DAEFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_159_544"
          x1="70.2215"
          y1="37.6882"
          x2="79.6376"
          y2="81.0115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92BBFF" />
          <stop offset="0.41" stopColor="#5B99FF" />
          <stop offset="0.7" stopColor="#4A81FF" />
          <stop offset="1" stopColor="#7DAEFF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_159_544"
          x1="20.1975"
          y1="130.699"
          x2="63.0138"
          y2="50.0512"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_159_544"
          x1="113.042"
          y1="130.699"
          x2="83.4783"
          y2="61.1632"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_159_544"
          x1="48.6931"
          y1="75.2187"
          x2="99.9734"
          y2="26.1679"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_159_544"
          x1="95.5143"
          y1="75.2187"
          x2="55.3819"
          y2="27.2827"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_159_544"
          x1="-20.6504"
          y1="167.1"
          x2="153"
          y2="0.996615"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LogoBapKYC
