import { FC } from 'react'

interface Props {
  width: number
  height: number
}

const IconTicked: FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#D7E5FD" />
      <rect
        x="4"
        y="9.53516"
        width="2"
        height="7"
        rx="1"
        transform="rotate(-45 4 9.53516)"
        fill="#317AF7"
      />
      <rect
        x="14.6064"
        y="6"
        width="2"
        height="10"
        rx="1"
        transform="rotate(45 14.6064 6)"
        fill="#317AF7"
      />
    </svg>
  )
}

export default IconTicked
