/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/prop-types */
import { Link } from 'react-router-dom'

import logoBAP from '@/assets/img/logo-bap-software-1.png'
import useScrollToSection from '@/components/CustomHook/useScrollToSection'
import { useToggle } from '@/components/CustomHook/useToggle'
import { IconList, IconRound } from '@/components/Icons'

// eslint-disable-next-line react/prop-types
const Header: React.FC = () => {
  const [togglenavMobile, handleToggleNavMobile] = useToggle(false)
  const [toggleProductMb, handleToggleProductMb] = useToggle(false)

  const { scrollToSection } = useScrollToSection()

  return (
    <>
      <header className="header">
        <nav className="navbar">
          <div className="logo">
            <Link to={'/'}>
              <img src={logoBAP} width={91} height={32} />
            </Link>
          </div>
          <ul className="nav-list">
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li className="header-dropdown">
              <Link to={''} className="show">
                AI Products <IconRound width={16} height={16} />
              </Link>

              <div className={'header-dropdown-content'}>
                <Link to="/bap-whisper-large-v3">BAP AI Speech2Text</Link>
                <Link to="/bap-ocr">BAP AI OCR</Link>
                <Link to="/BKYC">BAP AI bKYC</Link>
              </div>
            </li>
            <li className="header-dropdown">
              <Link to={''} className="show">
                Blockchain Products <IconRound width={16} height={16} />
              </Link>
              <div className="header-dropdown-content">
                <Link to="/products/nft-marketplace">NFT Marketplace</Link>
                <Link to="/products/crypto-exchange">
                  Crypto Exchange (CEX)
                </Link>
                <Link to="/products/decentralized-exchange">
                  Decentralized Exchange (DEX)
                </Link>
              </div>
            </li>
            <li className="header-dropdown">
              <Link to={''} className="show">
                ERP Products <IconRound width={16} height={16} />
              </Link>
              <div className="header-dropdown-content">
                <Link to="/bemo-cloud">Bemo Cloud</Link>
              </div>
            </li>
            <li>
              <Link onClick={() => scrollToSection('contact')} to={''}>
                Contact
              </Link>
            </li>
            <li>
              <a href={'/blog'}>Blog</a>
            </li>
          </ul>

          <button className="header-menu" onClick={handleToggleNavMobile}>
            <IconList width={24.8} height={18.92} />
          </button>
        </nav>
        <div className={'nav-mobile ' + (togglenavMobile ? 'active' : '')}>
          <ul className={'nav-list ' + (toggleProductMb && 'active')}>
            <li className="header-dropdown" onClick={handleToggleProductMb}>
              <span>
                <Link to={''}>Product </Link>
                <IconRound width={16} height={16} />
              </span>
            </li>
            <li>
              <Link onClick={() => scrollToSection('about-us')} to={''}>
                Company
              </Link>
            </li>
            <li>
              <a href={'/blog'}>Blog</a>
            </li>
            <li>
              <Link onClick={() => scrollToSection('contact')} to={''}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </header>
      <div className="separating-block"></div>
    </>
  )
}

export default Header
