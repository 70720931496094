/* eslint-disable react/display-name */
import './style.scss'

// import ListProduct from '@/components/Common/Item/Product'
import ProductCard from '@/components/Common/Item/ProductCard'

const bemoData = {
  id: 1,
  name: 'Bemo Cloud',
  img: '/img/product/bemo.png',
  video: '/video/product/bemo.webm',
  descriptions: [
    'Bemo Cloud cung cấp bộ giải pháp công nghệ toàn diện hỗ trợ cho quá trình chuyển đổi số và sự phát triển bền vững của doanh nghiệp.',
  ],
  link: 'https://bemo-cloud.com/',
  slug: 'bemo-cloud',
}

const ERPSection = () => {
  return (
    <section className="bg-semiWhite" id="ai-service">
      {/* ===== Container ==== */}
      <div className="">
        <h2 className="text-black text-center font-bold text-3xl uppercase pt-[40px] mb-[40px]">
          03 ERP System
        </h2>
        {/* ===== Content ==== */}
        <div className="flex-row justify-center items-center">
          <div className="flex justify-center items-center">
            <p className="text-[#636e80] max-w-[740px] text-center leading-6">
              At BAP, our team ranges from consultants proficient in at least
              two languages to well-trained ABAP developers skilled in coding
              and module features.
            </p>
          </div>
          {/* ===== List AI Product ==== */}
          <div className="flex justify-around items-center">
            <ProductCard key={0} data={bemoData} />
            <ProductCard key={1} data={bemoData} />
            <ProductCard key={2} data={bemoData} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ERPSection
