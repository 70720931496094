/* eslint-disable react/prop-types */
import { Link } from 'react-router-dom'

import BranchCompany from '@/components/Common/Item/BranchCompany'
import useScrollToSection from '@/components/CustomHook/useScrollToSection'
import {
  // IconFacebook,
  // IconLinkedIn,
  // IconTwitter,
  LogoBAP,
} from '@/components/Icons'

const Footer: React.FC = () => {
  const { scrollToSection } = useScrollToSection()

  return (
    <footer className="footer">
      <div className="mx-[69px]">
        <div className="wrapper-footer">
          {/* --- LOGO BAP --- */}
          <div className="mr-[69px]">
            <LogoBAP width={91} height={32} />
            <div className="icon-footer">
              <a
                href="https://www.facebook.com/bap32"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook text-xl"></i>
              </a>
              <a
                href={'https://www.linkedin.com/company/bap-it-jsc/'}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin text-xl"></i>
                {/* <IconLinkedIn width={20} height={20} /> */}
              </a>
              <a
                href="https://twitter.com/bapsoftware"
                target="_blank"
                rel="noreferrer"
              >
                {/* <IconTwitter width={20} height={20} /> */}
                <i className="fa-brands fa-x-twitter text-xl"></i>
              </a>
              <a
                href="https://www.youtube.com/@bapsoftware1020"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-youtube text-xl"></i>
              </a>
            </div>
          </div>
          <div className="company-branch">
            <div className="grid grid-cols-3 gap-11">
              <BranchCompany
                city="Ha Noi, Viet Nam"
                location="3F, 6th Element Tower, Nguyen Van Huyen St, Xuan La Ward, Tay Ho Dist, Ha Noi"
                phone="(+84) 986 241 041"
                mail="sales@bap.jp"
              />
              <BranchCompany
                city="Hue, Viet Nam"
                location="HCC Building, 2F, 28 Ly Thuong Kiet – Vinh Ninh – Hue"
                phone="(+84) 0234 6273 555"
                mail="sales@bap.jp"
              />
              <BranchCompany
                city="Da Nang, Viet Nam"
                location="BAP Building 180-182-184 Nguyen Tri Phuong, Chinh Gian, Thanh Khe District, Da Nang"
                phone="(+84) 0236 6565 115"
                mail="sales@bap.jp"
              />
              <BranchCompany
                city="Ho Chi Minh, Viet Nam"
                location="BAP Building, 236/29/18 Dien Bien Phu, Ward 17, Binh Thanh District, Ho Chi Minh"
                phone="(+84) 028 6270 1557"
                mail="sales@bap.jp"
              />
            </div>
          </div>
          <div className="ft-company-infor">
            <h3>Company information</h3>
            <ul>
              <li>
                <Link to={''} onClick={() => scrollToSection('about-us')}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to={''} onClick={() => scrollToSection('contact')}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="mx-auto text-center pb-[22px] mt-[20px] pt-[20px] text-sm border-t-[1px] border-solid border-[#ffffff14]">
          Copyright © 2024 BAP IT CO., JSC. All Rights Reserved.
        </div>
      </div>
    </footer>
  )
}

export default Footer
