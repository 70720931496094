import './style.scss'

import Layout from '@/components/Common/Layout/MainLayout/Layout'
import LogAIOCR from '@/components/Icons/LogAIOCR'

import item01 from '../../../../../assets/img/orc/orc_item_1.png'
import item02 from '../../../../../assets/img/orc/orc_item_2.png'
import item03 from '../../../../../assets/img/orc/orc_item_3.png'
import item04 from '../../../../../assets/img/orc/orc_item_4.png'
import bannerWhyAIImg from '../../../../../assets/img/orc/orc_video_thumb.png'
import { ProductInfomation } from '../Components/ProductInfomation'
import OCRTryItOut from './OCRTryItOut'

const projectInfo = {
  title: 'Why BAP AI OCR?',
  desc: 'By applying Optical Characteristic Recognition (OCR) on the base of Deep learning technology, BAP AI Read enables the identification of ID documents (i.e. National ID card, Driver’s License and Passport) and content extraction from images of various forms/bills (i.e. electric, water and retail bills, etc.) and printed documents. BAP AI Read is the ultimate tool for business to accelerate document processing and digitalization.',
  video_thumb: bannerWhyAIImg,
  viddeo: '',
  features: [
    {
      title: 'State-of-the-art accuracy',
      desc: `BAP AI OCR currently leads the market in Vietnamese text recognition quality. Thus, our clients enjoy greatly reduced costs for manual data correction in their CRM or data management system.`,
      img: item01,
    },
    {
      title: 'Customer experience enhancement',
      desc: 'Faster, painless registration and identification processes contribute to a smooth customer experience and reduce drop-off rate.',
      img: item02,
    },
    {
      title: 'Scalability and cost reduction',
      desc: 'By reducing both labor cost and time on data entry, our clients can improve their operational efficiency and achieve flexible scaling at the same time. There is no need to maintain an oversized workforce when peak loads can be handled by system expansion on demand.',
      img: item03,
    },
    {
      title: 'Seamless integration into any system',
      desc: 'Using APIs, BAP AI OCR can be integrated into any business system in minimal time.',
      img: item04,
    },
  ],
}

const OCR: React.FC = () => {
  // const [selectedOption, setSelectedOption] = useState('');

  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);

  // };
  return (
    <Layout>
      {/* --- hero-featured --- */}
      <section className="hero-featured">
        {/* --- featured__pattern --- */}
        <div className="absolute z-0 top-0 left-0 w-full h-full">
          <div className="hero-featured__overlay"></div>
          <div className="hero-featured__texture"></div>
          <div className="absolute bottom-0 left-0 bg-vecter1 bg-cover bg-no-repeat w-full h-full z-1 translate-x-[-50%]"></div>
          <div className="absolute bottom-0 right-0 bg-effect01 bg-cover bg-no-repeat w-[547px] h-[545px] z-1"></div>
          <div className="hero-featured__vector3"></div>
        </div>

        <div className="container px-8 md:py-32 mx-auto flex flex-wrap justify-evenly gap-4">
          <div className="hero-featured__main relative">
            {/* --- decor patttern -- */}
            <div className="absolute t-0 r-0 bg-mask_ellipse_1 bg-contain bg-no-repeat w-[200%] h-[200%] translate-x-[-30%] translate-y-[-20%] z-[-1]"></div>
            {/* --- main title --- */}
            <h1 className=" text-4xl md:text-6xl text-white font-bold md:mb-4 max-w-[470px] leading-[87px] md:leading-[87px]">
              BAP AI OCR
            </h1>
            <div className="hero-featured__btns">
              <a href="#" className="btn">
                Get Started
              </a>
              <a href="#" className="btn">
                Request A Demo
              </a>
            </div>
            <div>
              <LogAIOCR />
            </div>
          </div>
          <OCRTryItOut />
        </div>
      </section>
      <ProductInfomation projectInfo={projectInfo} />
    </Layout>
  )
}
// .btn-widget {
//   @apply inline-flex justify-around items-center py-2 px-4 bg-[#e4edff] hover:bg-[#cfdbf5] rounded-2xl gap-2;
// }

export default OCR
