import React from 'react'

import { IconLocation, IconMail, IconPhone } from '@/components/Icons'

interface Props {
  city: string
  location: string
  mail: string
  phone: string
}

const BranchCompany: React.FC<Props> = ({ city, location, mail, phone }) => {
  return (
    <div>
      <h3 className="text-sm">{city}</h3>
      <ul>
        <li>
          <IconLocation
            width={20}
            height={20}
            className="inline-block mr-[5px]"
          />
          {location}
        </li>
        <li>
          <IconPhone width={20} height={20} className="inline-block mr-[5px]" />
          {phone}
        </li>
        <li>
          <IconMail width={22} height={22} className="inline-block mr-[5px]" />
          {mail}
        </li>
      </ul>
    </div>
  )
}

export default BranchCompany
