import React from 'react'

const BtnUpload: React.FC = () => {
  return (
    <label className="inline-flex py-2 px-4 bg-[#e4edff] hover:bg-[#cfdbf5] rounded-2xl gap-2 justify-center items-center border-1 border-btn-317 border-solid w-full h-[54px] cursor-pointer">
      <svg
        width="21"
        height="18"
        viewBox="0 0 21 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.50005 14.0001C1.5 13.9355 1.5 13.8689 1.5 13.8002V4.2002C1.5 3.08009 1.5 2.51962 1.71799 2.0918C1.90973 1.71547 2.21547 1.40973 2.5918 1.21799C3.01962 1 3.58009 1 4.7002 1H16.3002C17.4203 1 17.9801 1 18.4079 1.21799C18.7842 1.40973 19.0905 1.71547 19.2822 2.0918C19.5 2.5192 19.5 3.07899 19.5 4.19691V13.8031C19.5 14.2881 19.5 14.6679 19.4822 14.9774M1.50005 14.0001C1.50082 14.9884 1.51337 15.5058 1.71799 15.9074C1.90973 16.2837 2.21547 16.5905 2.5918 16.7822C3.0192 17 3.57899 17 4.69691 17H16.3036C17.4215 17 17.9805 17 18.4079 16.7822C18.7842 16.5905 19.0905 16.2837 19.2822 15.9074C19.4055 15.6654 19.459 15.3813 19.4822 14.9774M1.50005 14.0001L6.26798 8.4375L6.26939 8.43596C6.69227 7.9426 6.90406 7.69551 7.15527 7.60645C7.37594 7.52821 7.61686 7.53004 7.83643 7.61133C8.08664 7.70397 8.29506 7.95387 8.71191 8.4541L11.3831 11.6595C11.769 12.1226 11.963 12.3554 12.1986 12.4489C12.4065 12.5313 12.6357 12.5406 12.8501 12.4773C13.0942 12.4053 13.3091 12.1904 13.7388 11.7607L14.2358 11.2637C14.6733 10.8262 14.8921 10.6076 15.1397 10.5361C15.3571 10.4734 15.5896 10.4869 15.7988 10.5732C16.037 10.6716 16.2302 10.9124 16.6167 11.3955L19.4822 14.9774M19.4822 14.9774L19.5 14.9996M13.5 7C12.9477 7 12.5 6.55228 12.5 6C12.5 5.44772 12.9477 5 13.5 5C14.0523 5 14.5 5.44772 14.5 6C14.5 6.55228 14.0523 7 13.5 7Z"
          stroke="#636E80"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <input accept="audio/*" type="file" style={{ display: 'none' }} />
      <span className="text-[#636e80]">Browse for file</span>
    </label>
  )
}

export default BtnUpload
