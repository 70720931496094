import APIClientFactory from './APIClientFactory'

export const postAudioSpeech2Text = async (file) => {
  const apiAxios = APIClientFactory.get('rest')
  // eslint-disable-next-line no-undef
  const formData = new FormData()
  formData.append('file', file)
  return await apiAxios.post('/api/audio/transcriptions', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getAudioSpeech2TextInfo = async (requestId) => {
  const apiAxios = APIClientFactory.get('rest')
  return await apiAxios.get(`/api/audio/transcriptions/${requestId}`)
}
