/* eslint-disable react/display-name */
import './style.scss'

// import { useEffect, useState } from 'react'
// import { IoIosArrowForward } from 'react-icons/io'
// import { IoIosArrowBack } from 'react-icons/io'
// import { TiTickOutline } from 'react-icons/ti'
import IconTicked from '@/components/Icons/IconTicked'

import homeIcon1 from '../../../../assets/img/home_icon_1.png'
import homeIcon2 from '../../../../assets/img/home_icon_2.png'
import homeIcon3 from '../../../../assets/img/home_icon_3.png'
// import SectionTitle from '@/components/Common/Item/SectionTitle'
// import Slides from './slides'

const AboutUs = () => {
  // const [current, setCurrent] = useState(0)
  // const autoSlide = true
  // const pre = () =>
  //   setCurrent((prevCurrent) =>
  //     prevCurrent === 0 ? Slides.length - 1 : prevCurrent - 1
  //   )

  // const next = () =>
  //   setCurrent((current) => (current === Slides.length - 1 ? 0 : current + 1))

  // useEffect(() => {
  //   if (!autoSlide) return
  //   const slideInterval = setInterval(next, 3000)
  //   return () => clearInterval(slideInterval)
  // }, [autoSlide])

  return (
    <section className="about-us bg-semiWhite" id="about-us">
      <div className="main-container pt-20">
        <h1 className="text-black text-3xl text-center uppercase font-bold mb-3">
          Why BAP?
        </h1>
        <p className="text-center text-[#636E80] leading-6 mt-[40px]">
          Team of 20+ certified AI developers.
          <br /> BAP&apos;s products and services are innovative and one step
          ahead of our competitors
        </p>

        <div className="flex justify-evenly items-center gap-x-6 mt-[36px]">
          <div className="flex justify-between items-center p-3.5 border rounded-[20px] border-[#D7D7D7] border-solid bg-white">
            <div className="flex justify-start items-start">
              <div className="basis-[20px] mt-1">
                <IconTicked width={20} height={20} />
              </div>
              <p className="text-sm leading-6 ml-3">
                Our expertise extends to BPO data labeling for enhanced accuracy
                and cost efficiency.
              </p>
            </div>
            <img
              src={homeIcon1}
              alt="BAP Feature Icon"
              className="w-[120px] h-[120px] object-contain"
            />
          </div>
          <div className="flex justify-between items-center p-3.5 border rounded-[20px] border-[#D7D7D7] border-solid bg-white">
            <div className="flex justify-start items-start">
              <div className="basis-[20px] mt-1">
                <IconTicked width={20} height={20} />
              </div>
              <p className="text-sm leading-6 ml-3">
                Our expertise extends to BPO data labeling for enhanced accuracy
                and cost efficiency.
              </p>
            </div>
            <img
              src={homeIcon2}
              alt="BAP Feature Icon"
              className="w-[120px] h-[120px] object-contain"
            />
          </div>
          <div className="flex justify-between items-center p-3.5 border rounded-[20px] border-[#D7D7D7] border-solid bg-white">
            <div className="flex justify-start items-start">
              <div className="basis-[20px] mt-1">
                <IconTicked width={20} height={20} />
              </div>

              <p className="text-sm leading-6 ml-3">
                Our expertise extends to BPO data labeling for enhanced accuracy
                and cost efficiency.
              </p>
            </div>
            <img
              src={homeIcon3}
              alt="BAP Feature Icon"
              className="w-[120px] h-[120px] object-contain"
            />
          </div>
        </div>

        <div className="row-container">
          {/* <div className="image-right">
            <div
              className="flex transition-transform ease-out duration-500 "
              style={{ transform: `translateX(-${current * 100}%)` }}
            >
              {Slides.map((s: string, index: number) => (
                <img key={index} src={s} alt={`slide-${index}`} />
              ))}
            </div>

            <div className="absolute inset-0 flex items-center justify-between p-3">
              <button
                onClick={pre}
                className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
              >
                <IoIosArrowBack />
              </button>
              <button
                onClick={next}
                className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
              >
                <IoIosArrowForward />
              </button>
            </div>

            <div className="absolute bottom-4 right-0 left-0">
              <div className="flex items-center justify-center gap-2">
                {Slides.map((s: string, index: number) => (
                  <div
                    key={index} // Add the key prop here
                    className={`transition-all w-2 h-2 bg-white rounded-full ${
                      current === index ? 'p-2' : 'bg-opacity-50'
                    }`}
                  ></div>
                ))}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default AboutUs
