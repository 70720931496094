import React, { useState } from 'react'

import bannerWhyAIImg from '../../../../../../assets/img/orc/orc_video_thumb.png'
import BtnUpload from './BtnUpload'
import DocumentTypeSelectBox, {
  DOCUMENT_TYPE_ID,
} from './DocumentTypeSelectBox'
import TableIDCard from './TableIDCard'

const OCRTryItOut: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState(DOCUMENT_TYPE_ID.ID)

  const handleOptionChange = (value) => {
    setSelectedOption(value)
  }

  return (
    <div className="relative flex flex-col justify-start items-start gap-4 bg-white max-w-[560px] rounded-[20px] py-8 px-6 md:py-[40px] md:px-[32px] w-full mb-8 md:mb-0 p-8">
      <DocumentTypeSelectBox
        onSelected={(e) => handleOptionChange(e)}
        selectedOption={selectedOption}
      />
      <BtnUpload />
      <p>Result</p>
      <div className="flex justify-between items-start gap-x-4 h-[332px]">
        <img
          src={bannerWhyAIImg}
          width={168}
          height={211}
          className="object-contain"
        ></img>
        <TableIDCard />
      </div>
    </div>
  )
}

export default OCRTryItOut
