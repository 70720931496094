import React from 'react'

import playBtnImg from '../../../../../assets/img/speech2text/btn-play.svg'

interface Feature {
  title: string;
  desc: string;
  img: string;
}

interface ProjectInfo {
  title: string;
  desc: string;
  video_thumb?: string;
  video?: string;
  features: Feature[];
}

interface Props {
  projectInfo: ProjectInfo;
}

export const ProductInfomation: React.FC<Props> = ({ projectInfo }) => {
  const { title, desc, video_thumb, video, features } = projectInfo

  return (
    <section className="bg-white m-auto px-8 py-16 md:px-10 lg:px-10 flex flex-col justify-center items-center">
      <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">
        {title}
      </h2>

      <p className="md:text-lg text-[#636E80] text-center leading-6 md:leading-7 max-w-[887px] mb-[40px]">
        {desc}
      </p>
      {/* --- videos --- */}
      <div className="mb-[80px]">
        <div className="relative flex justify-center items-center">
          {video_thumb && (
            <>
              <img
                src={video_thumb}
                alt="Why AI Introduction Banner"
                width={612}
                height={344}
              ></img>
              <img src={playBtnImg} className="absolute"></img>
            </>
          )}
        </div>
        <video src={video} className="w-[612px] h-[344px] hidden"></video>
      </div>

      {/* ---WhyAI Items --- */}
      <div className="flex flex-col justify-center items-center w-full gap-10">
        {/* --- item 1 --- */}
        {features.map((item, index) => (
          <div
            className={`flex justify-between items-center bg-slate-20 gap-8 ${
              index % 2 ? 'flex-row-reverse' : ''
            }`}
            key={index}
          >
            {/* --- item title & desc --- */}
            <div className="flex flex-col justify-center items-start max-w-md lg:max-w-lg w-full">
              <h3 className="text-bold text-2xl md:text-3xl mb-6">
                {item.title}
              </h3>
              <p className="text-normal text-[#636E80] leading-7">
                {item.desc}
              </p>
            </div>
            {/* ---item image -- */}
            <div className="md:max-w-[340px] md:max-h-[340px]">
              <img
                src={item.img}
                alt="Why AI Item feature Image"
                className="w-full h-full object-contain"
              ></img>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
