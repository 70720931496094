// import React, { useState } from 'react'
import React from 'react'

import bannerWhyAIImg from '../../../../../../assets/img/orc/orc_video_thumb.png'
import BtnScanFaceID from './BtnScanFaceID'
import BtnUpload from './BtnUpload'
import TableIDCard from './TableIDCard'

function OCRTryItOut() {
  return (
    <div className="relative flex flex-col justify-start items-start gap-4 bg-white max-w-[560px] rounded-[20px] py-8 px-6 md:py-[40px] md:px-[32px] w-full mb-8 md:mb-0 p-8">
      <div className="flex justify-around items-center w-full gap-4">
        <BtnUpload />
        <BtnScanFaceID />
      </div>
      <p>Result</p>
      <div className="flex justify-between items-start gap-x-4 h-[332px]">
        <img
          src={bannerWhyAIImg}
          width={168}
          height={211}
          className="object-contain"
        ></img>
        <TableIDCard />
      </div>
    </div>
  )
}

export default OCRTryItOut
