import React from 'react'

const BtnScanFaceID = () => {
  return (
    <button className="inline-flex py-2 px-4 bg-[#e4edff] hover:bg-[#cfdbf5] rounded-2xl gap-2 justify-center items-center border-1 border-btn-317 border-solid w-full h-[54px] cursor-pointer">
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.50532 19.208H8.44845C8.94281 19.208 9.34418 19.6094 9.34418 20.1037C9.34418 20.5981 8.94281 20.9995 8.44845 20.9995H5.46267C4.50082 20.9995 3.71387 20.2125 3.71387 19.2507V16.2649C3.71387 15.7705 4.11523 15.3691 4.60959 15.3691C5.10395 15.3691 5.50532 15.7705 5.50532 16.2649V19.208Z"
          fill="#636E80"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9228 19.208V16.2649C19.9228 15.7705 20.3242 15.3691 20.8186 15.3691C21.3129 15.3691 21.7143 15.7705 21.7143 16.2649V19.2507C21.7143 20.2125 20.9273 20.9995 19.9655 20.9995H16.9797C16.4853 20.9995 16.084 20.5981 16.084 20.1037C16.084 19.6094 16.4853 19.208 16.9797 19.208H19.9228Z"
          fill="#636E80"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9228 4.79147H16.9797C16.4853 4.79147 16.084 4.3901 16.084 3.89574C16.084 3.40138 16.4853 3 16.9797 3H19.9655C20.9273 3 21.7143 3.78697 21.7143 4.74882V7.7346C21.7143 8.22896 21.3129 8.63034 20.8186 8.63034C20.3242 8.63034 19.9228 8.22896 19.9228 7.7346V4.79147Z"
          fill="#636E80"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.50532 4.79147V7.7346C5.50532 8.22896 5.10395 8.63034 4.60959 8.63034C4.11523 8.63034 3.71387 8.22896 3.71387 7.7346V4.74882C3.71387 3.78697 4.50082 3 5.46267 3H8.44845C8.94281 3 9.34418 3.40138 9.34418 3.89574C9.34418 4.3901 8.94281 4.79147 8.44845 4.79147H5.50532Z"
          fill="#636E80"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75656 12.896C3.2622 12.896 2.86084 12.4946 2.86084 12.0002C2.86084 11.5059 3.2622 11.1045 3.75656 11.1045H21.6713C22.1656 11.1045 22.567 11.5059 22.567 12.0002C22.567 12.4946 22.1656 12.896 21.6713 12.896H3.75656Z"
          fill="#636E80"
        />
      </svg>
      <span className="text-[#636e80]">Scan face ID</span>
    </button>
  )
}

export default BtnScanFaceID
