import './style.scss'

import React from 'react'

import Layout from '@/components/Common/Layout/MainLayout/Layout'
import LogoWhisperLarge from '@/components/Icons/LogoWhisperLarge'

import item01 from '../../../../../assets/img/speech2text/artboard_ai_01.png'
import item02 from '../../../../../assets/img/speech2text/artboard_micro_02.png'
import item03 from '../../../../../assets/img/speech2text/artboard_timer_03.png'
import bannerWhyAIImg from '../../../../../assets/img/speech2text/banner-why-ai-video.png'
import { ProductInfomation } from '../Components/ProductInfomation'
import TryItOutForm from './TryItOutForm'

const projectInfo = {
  title: 'Why BAP.AI  Read?',
  desc: 'By applying Optical Characteristic Recognition (OCR) on the base of Deep learning technology, BAP AI Read enables the identification of ID documents (i.e. National ID card, Driver’s License and Passport) and content extraction from images of various forms/bills (i.e. electric, water and retail bills, etc.) and printed documents. BAP AI Read is the ultimate tool for business to accelerate document processing and digitalization.',
  video_thumb: bannerWhyAIImg,
  features: [
    {
      title: 'Why BAP.AI Speech To Text',
      desc: `SpeechBAP is a reliable and secure web-based speech-to-text tool that
      enables you to quickly and accurately transcribe your audio and video
      recordings, as well as dictate your notes instead of typing, saving you
      time and effort. With features like voice commands for punctuation and
      formatting, automatic capitalization, and easy import/export options,
      SpeechBAP provides an efficient and user-friendly dictation and
      transcription experience. Proudly serving millions of users since,
      SpeechBAP is the go-to tool for anyone who needs fast, accurate &
      private transcription.`,
      img: item01,
    },
    {
      title: 'Automatic proper nouns transcription and punctuation',
      desc: `STT formats specific contextual results and can correctly transcribe
      proper nouns (such as proper names, names of place) and appropriate language formats (such as dates and phone numbers).
      Using machine learning technology, STT service automatically punctuates after each break.`,
      img: item02,
    },
    {
      title: 'Real-time or pre-recorded audio support',
      desc: 'Audio input can be received directly from the microphone of the online application or sent from an audio file.',
      img: item03,
    },
  ],
}

const WhisperV3: React.FC = () => {
  return (
    <Layout>
      {/* --- hero-featured --- */}
      <section className="hero-featured">
        {/* --- featured__pattern --- */}
        <div className="absolute z-0 top-0 left-0 w-full h-full">
          <div className="hero-featured__overlay"></div>
          <div className="hero-featured__texture"></div>
          <div className="absolute bottom-0 left-0 bg-vecter1 bg-cover bg-no-repeat w-full h-full z-1 translate-x-[-50%]"></div>
          <div className="absolute bottom-0 right-0 bg-effect01 bg-cover bg-no-repeat w-[547px] h-[545px] z-1"></div>
          <div className="hero-featured__vector3"></div>
        </div>
        <div className="container px-8 md:py-32 mx-auto flex flex-wrap justify-evenly gap-4">
          <div className="hero-featured__main relative">
            {/* --- decor patttern -- */}
            <div className="absolute t-0 r-0 bg-mask_ellipse_1 bg-contain bg-no-repeat w-[200%] h-[200%] translate-x-[-30%] translate-y-[-20%] z-[-1]"></div>
            {/* --- main title --- */}
            <h1 className=" text-4xl md:text-6xl text-white font-bold md:mb-4 max-w-[470px] leading-[87px] md:leading-[87px]">
              Convert speech to text
            </h1>
            <div className="hero-featured__btns">
              <a href="#" className="btn">
                Get Started
              </a>
              <a href="#" className="btn">
                Request A Demo
              </a>
            </div>
            <div>
              <LogoWhisperLarge width={357} height={144} />
            </div>
          </div>
          <TryItOutForm />
        </div>
      </section>
      <ProductInfomation projectInfo={projectInfo} />
    </Layout>
  )
}

export default WhisperV3
