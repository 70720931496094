import customAxios from '@/libs/axios'

const APIClients = {
  rest: customAxios,
  // graphql: undefined,
}

const APIClientFactory = {
  get: (name: keyof typeof APIClients) => APIClients[name],
}

export default APIClientFactory
