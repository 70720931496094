/* eslint-disable simple-import-sort/imports */
import './style.scss'

// import BannerTitle from '@/components/Common/Item/BannerTitle'
import Layout from '@/components/Common/Layout/MainLayout/Layout'
import Bannervideo from '@/components/Common/Pages/BannerVideo'
// import WhyBAP from '@/components/Common/Pages/Top/WhyBAP'
import AboutUs from '@/components/Common/Pages/AboutUs'
import OurPartner from '@/components/Common/Pages/Top/OurPartner/indexV2'
import AISection from './AISection/indexV2'
import BlockchainSection from './BlockchainSection/indexV2'
import ERPSection from './ERPSection/indexV2'

const Blog: React.FC = () => {
  return (
    <Layout>
      <Bannervideo />
      <AboutUs />
      <AISection />
      <BlockchainSection />
      <ERPSection />
      <OurPartner />
    </Layout>
  )
}

export default Blog
