import React from 'react'

const TableIDCard: React.FC = () => {
  return (
    <div className="flex flex-col gap-2 justify-start items-cente">
      <div className="flex justify-start gap-4 w-full">
        <span className="w-[95px] inline-block font-bold text-sm">
          Full Name
        </span>
        <span className="">DAVIS SIPHRON</span>
      </div>
      <div className="flex justify-start gap-4 w-full">
        <span className="w-[95px] inline-block font-bold text-sm">Number</span>
        <span className="w-[195px] text-black-400 leading-5 text-sm">
          0123456789
        </span>
      </div>
      <div className="flex justify-start gap-4 w-full">
        <span className="w-[95px] inline-block font-bold text-sm">
          Birthday
        </span>
        <span className="w-[195px] text-black-400 leading-5 text-sm">
          12/05/1990
        </span>
      </div>
      <div className="flex justify-start gap-4 w-full">
        <span className="w-[95px] inline-block font-bold text-sm">
          Nationality
        </span>
        <span className="w-[195px] text-black-400 leading-5 text-sm">
          VIET NAM
        </span>
      </div>
      <div className="flex justify-start gap-4 w-full">
        <span className="w-[95px] inline-block font-bold text-sm">
          Hometown
        </span>
        <span className="w-[195px] text-black-400 leading-5 text-sm">
          2352, P1, Q1, TP HO CHI MINH
        </span>
      </div>
      <div className="flex justify-start gap-4 w-full">
        <span className="w-[95px] inline-block font-bold text-sm">Address</span>
        <span className="w-[195px] text-black-400 leading-5 text-sm">
          123/12/12 BINH THANH, P1, 10, TP HO CHI MINH
        </span>
      </div>
      <div className="flex justify-start gap-4 w-full">
        <span className="w-[95px] inline-block font-bold text-sm">Date of</span>
        <span className="w-[195px] text-black-400 leading-5 text-sm">
          01/08/2025
        </span>
      </div>
    </div>
  )
}

export default TableIDCard
