import { FC } from 'react'

interface MySVGProps {
  width: number
  height: number
}

const LogoWhisperLarge: FC<MySVGProps> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 459 189"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_75_1715)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M346.233 139.593C317.196 139.593 293.656 116.054 293.656 87.0158C293.656 57.9782 317.196 34.4385 346.233 34.4385C375.271 34.4385 398.811 57.9782 398.811 87.0158C398.811 97.2772 395.871 106.852 390.788 114.944C390.708 115.071 390.689 115.228 390.736 115.37L398.494 138.645C398.625 139.035 398.253 139.407 397.862 139.277L374.588 131.519C374.445 131.471 374.289 131.491 374.161 131.571C366.069 136.654 356.495 139.593 346.233 139.593Z"
          fill="url(#paint0_linear_75_1715)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M406.322 162.126C377.284 162.126 353.744 138.587 353.744 109.549C353.744 80.5114 377.284 56.9717 406.322 56.9717C435.359 56.9717 458.899 80.5114 458.899 109.549C458.899 119.81 455.959 129.385 450.876 137.477C450.796 137.605 450.777 137.761 450.825 137.903L458.583 161.178C458.713 161.569 458.341 161.94 457.95 161.81L434.676 154.052C434.533 154.005 434.377 154.024 434.25 154.104C426.158 159.187 416.583 162.126 406.322 162.126Z"
        fill="url(#paint1_linear_75_1715)"
      />
      <g filter="url(#filter1_bd_75_1715)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M331.211 162.126C372.694 162.126 406.322 128.498 406.322 87.0158C406.322 45.5334 372.694 11.9053 331.211 11.9053C289.729 11.9053 256.101 45.5334 256.101 87.0158C256.101 101.711 260.321 115.421 267.616 126.999C267.696 127.127 267.715 127.283 267.668 127.426L256.417 161.178C256.287 161.569 256.658 161.94 257.049 161.81L290.801 150.559C290.944 150.512 291.1 150.531 291.227 150.611C302.806 157.906 316.516 162.126 331.211 162.126Z"
          fill="url(#paint2_linear_75_1715)"
          shapeRendering="crispEdges"
        />
        <path
          d="M289.853 147.713L289.853 147.713L260.844 157.383L270.514 128.374L267.668 127.426L270.514 128.374C270.847 127.375 270.711 126.284 270.154 125.4L267.616 126.999L270.154 125.4C263.152 114.287 259.101 101.129 259.101 87.0158C259.101 47.1903 291.386 14.9053 331.211 14.9053C371.037 14.9053 403.322 47.1903 403.322 87.0158C403.322 126.841 371.037 159.126 331.211 159.126C317.098 159.126 303.94 155.075 292.827 148.073L292.826 148.073C291.943 147.516 290.852 147.38 289.853 147.713Z"
          stroke="url(#paint3_linear_75_1715)"
          strokeWidth="6"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter2_di_75_1715)">
        <rect
          width="15.0221"
          height="15.0221"
          rx="7.51105"
          transform="matrix(1 0 0 -1 293.656 94.5273)"
          fill="url(#paint4_linear_75_1715)"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter3_di_75_1715)">
        <rect
          width="15.0221"
          height="15.0221"
          rx="7.51105"
          transform="matrix(1 0 0 -1 323.7 94.5273)"
          fill="url(#paint5_linear_75_1715)"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter4_di_75_1715)">
        <rect
          width="15.0221"
          height="15.0221"
          rx="7.51105"
          transform="matrix(1 0 0 -1 353.744 94.5273)"
          fill="url(#paint6_linear_75_1715)"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter5_f_75_1715)">
        <ellipse
          cx="79.5"
          cy="81.501"
          rx="29.5"
          ry="42.5"
          fill="url(#paint7_linear_75_1715)"
        />
      </g>
      <g filter="url(#filter6_f_75_1715)">
        <path
          d="M98.3943 66.2033C95.6862 63.4205 91.2988 63.4205 88.5906 66.2033C85.8825 68.9861 85.8825 73.4945 88.5906 76.2772C89.9044 77.6272 90.6283 79.4144 90.6283 81.3074C90.6283 83.2064 89.9039 84.9947 88.5906 86.3441C85.8825 89.1273 85.8825 93.6353 88.5906 96.4181C91.2988 99.2008 95.6862 99.2008 98.3943 96.4181C102.328 92.3759 104.494 87.0122 104.494 81.3078C104.494 75.6092 102.328 70.2455 98.3943 66.2033Z"
          fill="url(#paint8_linear_75_1715)"
        />
      </g>
      <g filter="url(#filter7_f_75_1715)">
        <path
          d="M61.5617 96.4315C64.2698 99.2142 68.6573 99.2142 71.3654 96.4315C74.0735 93.6487 74.0735 89.1403 71.3654 86.3575C70.0517 85.0076 69.3278 83.2203 69.3278 81.3274C69.3278 79.4283 70.0522 77.6401 71.3654 76.2907C74.0735 73.5074 74.0735 68.9995 71.3654 66.2167C68.6573 63.4339 64.2698 63.4339 61.5617 66.2167C57.628 70.2589 55.4617 75.6226 55.4617 81.3269C55.4617 87.0256 57.6284 92.3893 61.5617 96.4315Z"
          fill="url(#paint9_linear_75_1715)"
        />
      </g>
      <path
        d="M137.614 25.9103C134.906 23.1276 130.518 23.1276 127.81 25.9103C125.102 28.6931 125.102 33.2015 127.81 35.9843C139.598 48.089 146.09 64.1881 146.09 81.3159C146.09 98.4437 139.597 114.549 127.81 126.655C125.102 129.437 125.102 133.946 127.81 136.729C130.518 139.511 134.905 139.511 137.614 136.729C152.021 121.931 159.956 102.256 159.956 81.3154C159.956 60.3819 152.021 40.7073 137.614 25.9103Z"
        fill="url(#paint10_linear_75_1715)"
      />
      <path
        d="M117.995 46.0617C115.287 43.2789 110.899 43.2789 108.192 46.0617C105.483 48.8444 105.483 53.3595 108.192 56.1356C114.752 62.8701 118.36 71.8097 118.36 81.3061C118.36 90.8025 114.752 99.7421 108.192 106.484C105.483 109.267 105.483 113.775 108.192 116.558C110.9 119.34 115.287 119.34 117.995 116.558C127.175 107.131 132.226 94.6082 132.226 81.3066C132.226 67.9973 127.169 55.4813 117.995 46.0617Z"
        fill="url(#paint11_linear_75_1715)"
      />
      <g filter="url(#filter8_bd_75_1715)">
        <path
          d="M98.3943 66.2033C95.6862 63.4205 91.2988 63.4205 88.5906 66.2033C85.8825 68.9861 85.8825 73.4945 88.5906 76.2772C89.9044 77.6272 90.6283 79.4144 90.6283 81.3074C90.6283 83.2064 89.9039 84.9947 88.5906 86.3441C85.8825 89.1273 85.8825 93.6353 88.5906 96.4181C91.2988 99.2008 95.6862 99.2008 98.3943 96.4181C102.328 92.3759 104.494 87.0122 104.494 81.3078C104.494 75.6092 102.328 70.2455 98.3943 66.2033Z"
          fill="url(#paint12_linear_75_1715)"
        />
      </g>
      <path
        d="M22.342 136.725C25.0501 139.508 29.4376 139.508 32.1457 136.725C34.8538 133.943 34.8538 129.434 32.1457 126.651C20.3583 114.547 13.8659 98.4476 13.8659 81.3198C13.8659 64.1921 20.3588 48.0863 32.1457 35.9811C34.8538 33.1983 34.8538 28.6899 32.1457 25.9071C29.4376 23.1243 25.0506 23.1243 22.342 25.9071C7.93459 40.7051 -0.00021458 60.3797 -0.000216361 81.3203C-0.000218142 102.254 7.93458 121.928 22.342 136.725Z"
        fill="url(#paint13_linear_75_1715)"
      />
      <path
        d="M41.9622 116.574C44.6703 119.357 49.0578 119.357 51.7654 116.574C54.4736 113.791 54.4736 109.276 51.7655 106.5C45.2052 99.7656 41.5966 90.826 41.5966 81.3296C41.5966 71.8333 45.2052 62.8936 51.7655 56.1521C54.474 53.3688 54.474 48.8609 51.7655 46.0781C49.0573 43.2953 44.6699 43.2953 41.9622 46.0781C32.782 55.5048 27.731 68.0275 27.731 81.3292C27.731 94.6384 32.7884 107.154 41.9622 116.574Z"
        fill="url(#paint14_linear_75_1715)"
      />
      <g filter="url(#filter9_bd_75_1715)">
        <path
          d="M61.5617 96.4315C64.2698 99.2142 68.6573 99.2142 71.3654 96.4315C74.0735 93.6487 74.0735 89.1403 71.3654 86.3575C70.0517 85.0076 69.3278 83.2203 69.3278 81.3274C69.3278 79.4283 70.0522 77.6401 71.3654 76.2907C74.0735 73.5074 74.0735 68.9995 71.3654 66.2167C68.6573 63.4339 64.2698 63.4339 61.5617 66.2167C57.628 70.2589 55.4617 75.6226 55.4617 81.3269C55.4617 87.0256 57.6284 92.3893 61.5617 96.4315Z"
          fill="url(#paint15_linear_75_1715)"
        />
      </g>
      <g filter="url(#filter10_bd_75_1715)">
        <path
          d="M125.51 113.13C125.51 110.286 123.27 107.983 120.501 107.983C117.733 107.983 115.492 110.286 115.492 113.13C115.492 132.995 99.093 149.16 79.7614 149.16C60.4298 149.16 44.0306 132.995 44.0306 113.13C44.0306 110.286 41.7902 107.983 39.0217 107.983C36.2532 107.983 34.0127 110.286 34.0127 113.13C34.0127 135.5 50.1689 154.012 70.7944 158.295C73.032 158.76 74.7524 160.658 74.7524 162.944V165.248C74.7524 167.734 72.7377 169.748 70.2524 169.748H59.0575C56.289 169.748 54.0486 172.051 54.0486 174.896C54.0486 177.74 56.289 180.043 59.0575 180.043H100.465C103.234 180.043 105.474 177.74 105.474 174.896C105.474 172.051 103.234 169.748 100.465 169.748H89.2703C86.7851 169.748 84.7703 167.734 84.7703 165.248V162.944C84.7703 160.658 86.4908 158.76 88.7284 158.295C109.354 154.013 125.51 135.5 125.51 113.13Z"
          fill="url(#paint16_linear_75_1715)"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter11_bd_75_1715)">
        <path
          d="M79.7626 25.6299C65.9534 25.6299 54.0498 37.1756 54.0498 51.3652V113.13C54.0498 127.32 65.9537 138.865 79.7626 138.865C93.5718 138.865 105.475 127.319 105.475 113.13V51.3655C105.475 37.1756 93.5715 25.6299 79.7626 25.6299Z"
          fill="url(#paint17_linear_75_1715)"
          shapeRendering="crispEdges"
        />
        <path
          d="M57.0498 51.3652C57.0498 38.8577 67.5849 28.6299 79.7626 28.6299C91.94 28.6299 102.475 38.8576 102.475 51.3655C102.475 51.3655 102.475 51.3656 102.475 51.3656V113.13C102.475 125.637 91.9404 135.865 79.7626 135.865C67.5851 135.865 57.0498 125.638 57.0498 113.13V51.3652Z"
          stroke="url(#paint18_linear_75_1715)"
          strokeWidth="6"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_75_1715"
          x="259.656"
          y="0.438477"
          width="173.155"
          height="173.154"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_75_1715"
          />
        </filter>
        <filter
          id="filter1_bd_75_1715"
          x="252.101"
          y="7.90527"
          width="158.221"
          height="162.221"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_75_1715"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_75_1715"
            result="effect2_dropShadow_75_1715"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_75_1715"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_di_75_1715"
          x="292.156"
          y="79.0049"
          width="18.022"
          height="19.5225"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_75_1715"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_75_1715"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_75_1715"
          />
        </filter>
        <filter
          id="filter3_di_75_1715"
          x="322.2"
          y="79.0049"
          width="18.022"
          height="19.5225"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_75_1715"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_75_1715"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_75_1715"
          />
        </filter>
        <filter
          id="filter4_di_75_1715"
          x="352.244"
          y="79.0049"
          width="18.022"
          height="19.5225"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_75_1715"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_75_1715"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_75_1715"
          />
        </filter>
        <filter
          id="filter5_f_75_1715"
          x="16"
          y="5.00098"
          width="127"
          height="153"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_75_1715"
          />
        </filter>
        <filter
          id="filter6_f_75_1715"
          x="52.5596"
          y="30.1162"
          width="85.9346"
          height="102.389"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_75_1715"
          />
        </filter>
        <filter
          id="filter7_f_75_1715"
          x="21.4619"
          y="30.1299"
          width="85.9346"
          height="102.389"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_75_1715"
          />
        </filter>
        <filter
          id="filter8_bd_75_1715"
          x="82.5596"
          y="60.1162"
          width="25.9346"
          height="46.3887"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_75_1715"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_75_1715"
            result="effect2_dropShadow_75_1715"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_75_1715"
            result="shape"
          />
        </filter>
        <filter
          id="filter9_bd_75_1715"
          x="51.4619"
          y="60.1299"
          width="25.9346"
          height="46.3887"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_75_1715"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_75_1715"
            result="effect2_dropShadow_75_1715"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_75_1715"
            result="shape"
          />
        </filter>
        <filter
          id="filter10_bd_75_1715"
          x="30.0127"
          y="103.983"
          width="99.4976"
          height="84.0596"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_75_1715"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_75_1715"
            result="effect2_dropShadow_75_1715"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_75_1715"
            result="shape"
          />
        </filter>
        <filter
          id="filter11_bd_75_1715"
          x="50.0498"
          y="21.6299"
          width="59.4258"
          height="125.235"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_75_1715"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_75_1715"
            result="effect2_dropShadow_75_1715"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_75_1715"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_75_1715"
          x1="428.928"
          y1="40.9845"
          x2="402.361"
          y2="163.22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92BBFF" />
          <stop offset="0.41" stopColor="#5B99FF" />
          <stop offset="0.7" stopColor="#4A81FF" />
          <stop offset="1" stopColor="#7DAEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_75_1715"
          x1="489.017"
          y1="63.5177"
          x2="462.45"
          y2="185.753"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92BBFF" />
          <stop offset="0.41" stopColor="#5B99FF" />
          <stop offset="0.7" stopColor="#4A81FF" />
          <stop offset="1" stopColor="#7DAEFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_75_1715"
          x1="233.567"
          y1="177.148"
          x2="406.322"
          y2="11.9053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_75_1715"
          x1="391.3"
          y1="177.148"
          x2="256.101"
          y2="15.6608"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_75_1715"
          x1="-2.25332"
          y1="16.5243"
          x2="15.0221"
          y2="-1.28712e-07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_75_1715"
          x1="-2.25332"
          y1="16.5243"
          x2="15.0221"
          y2="-1.28712e-07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_75_1715"
          x1="-2.25332"
          y1="16.5243"
          x2="15.0221"
          y2="-1.28712e-07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_75_1715"
          x1="33.1015"
          y1="44.2923"
          x2="62.6088"
          y2="138.527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92BBFF" />
          <stop offset="0.41" stopColor="#5B99FF" />
          <stop offset="0.7" stopColor="#4A81FF" />
          <stop offset="1" stopColor="#7DAEFF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_75_1715"
          x1="101.218"
          y1="40.7809"
          x2="78.8945"
          y2="43.9153"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64A9FF" />
          <stop offset="1" stopColor="#317AF7" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_75_1715"
          x1="58.7382"
          y1="121.854"
          x2="81.0615"
          y2="118.719"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64A9FF" />
          <stop offset="1" stopColor="#317AF7" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_75_1715"
          x1="153.712"
          y1="-54.2074"
          x2="110.61"
          y2="-50.7584"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64A9FF" />
          <stop offset="1" stopColor="#317AF7" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_75_1715"
          x1="127.464"
          y1="-6.69439"
          x2="94.67"
          y2="-3.6123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64A9FF" />
          <stop offset="1" stopColor="#317AF7" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_75_1715"
          x1="101.218"
          y1="40.7809"
          x2="78.8945"
          y2="43.9153"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64A9FF" />
          <stop offset="1" stopColor="#317AF7" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_75_1715"
          x1="6.24364"
          y1="216.843"
          x2="49.3461"
          y2="213.394"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64A9FF" />
          <stop offset="1" stopColor="#317AF7" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_75_1715"
          x1="32.493"
          y1="169.33"
          x2="65.287"
          y2="166.248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64A9FF" />
          <stop offset="1" stopColor="#317AF7" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_75_1715"
          x1="58.7382"
          y1="121.854"
          x2="81.0615"
          y2="118.719"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64A9FF" />
          <stop offset="1" stopColor="#317AF7" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_75_1715"
          x1="20.2881"
          y1="187.248"
          x2="101.695"
          y2="88.3755"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_75_1715"
          x1="46.336"
          y1="150.189"
          x2="141.606"
          y2="108.803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_75_1715"
          x1="100.333"
          y1="150.189"
          x2="13.5534"
          y2="103.115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LogoWhisperLarge
