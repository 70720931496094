import './style.scss'

// import aiBotImg from '../../../../assets/img/Ai_Bot.png'
// import aiBotImg from '../../../../assets/img/technology/

const Bannervideo: React.FC = () => {
  return (
    <section id="banner">
      <div className="banner-video">
        <div className="absolute z-0 top-0 left-0 w-full h-[520px]">
          <div className="absolute w-full h-full bottom-0 left-0 bg-banner_overlay bg-cover bg-fixed bg-opacity-[0.7]"></div>
          {/* <div className="absolute w-full h-full top-0 left-0 bg-banner_texture bg-cover bg-opacity-[0.4]"></div> */}
          <div className="absolute w-full h-full bottom-0 left-0 bg-technology_pattern bg-cover"></div>
          <img></img>
          <div className="absolute bottom-0 left-0 bg-mask_ellipse_1 bg-contain bg-no-repeat w-[485px] h-[485px]"></div>
          <div className="absolute top-[64px] right-[120px] bg-ai_bot bg-contain bg-no-repeat w-[330px] h-[386px]"></div>
        </div>
        <div className="content-container">
          <p className="color-white title">Empowering Tomorrow, Today:</p>
          <div className="color-white child-content">
            <p>Crafting Intelligent Solutions for a Connected World.</p>
            <br />
            <p>
              Welcome to DemoHub by BAP! Explore AI, Blockchain, and ERP
              solutions to revolutionize your business. Experience innovation
              like never before. Dive in now!
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Bannervideo
