import './style.scss'

import { FC } from 'react'
import { useState } from 'react'
import { LuPlayCircle } from 'react-icons/lu'

import Popup from './popup'

interface ProductCardProps {
  data: {
    id: number
    img: string
    video: string
    name: string
    descriptions: any
    slug: string
  }
}

const ProductCard: FC<ProductCardProps> = ({ data }): JSX.Element => {
  const [url, setUrl] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const openPopup = (videoUrl: string) => {
    setUrl(videoUrl)
    setIsOpen(true)
  }

  const closePopup = () => {
    setUrl('')
    setIsOpen(false)
  }

  return (
    <>
      <Popup video={url} isOpen={isOpen} onClose={closePopup} />
      <div className="products">
        <div key={data.id} className="card">
          <div className="face face1">
            <div className="content">
              <img src={data.img} />
              <LuPlayCircle
                onClick={() => {
                  data.video !== '' && openPopup(data.video)
                }}
              />
              <div className="product-name">
                <h3>{data.name}</h3>
              </div>
            </div>
          </div>
          <div className="face face2">
            <div className="content">
              <p>{data.descriptions}</p>
              <a href={`/product/${data.slug}`}>Read More</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductCard
