/* eslint-disable react/display-name */

import './style.scss'

// import ListProduct from '@/components/Common/Item/Product'
import ProductCard from '@/components/Common/Item/ProductCard'

const projects = [
  {
    id: 1,
    name: 'CHATBOT WITH YOUR OWN DATA',
    img: '/img/product/chatbot.webp',
    video: '/video/product/chatbot.webm',
    descriptions: [
      'Import data from URL and documents (docx/ pdf/ excel/txt)1',
      'Input: question for chatbot. Output: chatbot answer in1',
      'streaming response format1.',
    ],
    content: {
      problem:
        '<ul>\r\n    <li>Nowadays, businesses struggle with slow and limited customer support, resulting in frustrated customers and potential revenue loss.&nbsp;</li>\r\n    <li>A solution is needed to provide quick, 24/7 support through an intelligent chatbot. This bot should understand and address customer queries instantly, improving overall satisfaction and loyalty.</li>\r\n    <li>&nbsp;Chatbot can use knowledge that business supplied it from documents or URLs. Chatbot can only respond to customers using information from its knowledge.</li>\r\n</ul>',
      input_output:
        '<p>Input: </p>\r\n<ul>\r\n    <li>Customer can import data for their chatbot from URL or documents (like .docx/.txt/.pdf/...).&nbsp;</li>\r\n    <li>Customer can input question for chatbot.</li>\r\n</ul>\r\n<p>Output:&nbsp;</p>\r\n<ul>\r\n    <li>&nbsp;Chatbot answer customer&apos;s question in streaming response format (like ChatGPT streaming).</li>\r\n</ul>',
      solution: {
        detail:
          '<p>For this problem, we used langchain with OpenAI API with some vector embedding processing technique to save and split supplied data to VectorDatabase (FAISS) combine with ConversationRetrievalChain to chat with chatbot.</p>',
      },
      opportunity:
        'With this chatbot, business can deal with problem about customer services, siness will not need to spend too much manpower on customer support by optimizing this chatbot abilities...',
    },
    slug: 'chatbot-with-your-own-data',
  },
  {
    id: 2,
    name: 'GARBAGE CLASSIFICATION',
    img: '/img/product/GARBAGE-CLASSIFICATION.webp',
    video: '/video/product/GARBAGE-CLASSIFICATION.webm',
    descriptions:
      'Our Garbage Classification solution swiftly identifies waste types (BioDegradable, Non-BioDegradable, Hazardous) by analyzing single garbage images.',
    content: {
      problem:
        '<ul>\r\n<li>In some households, all types of waste are discarded together in one bag/trash without knowing which ones can be recycled and reused for human life.</li>\r\n<li>Some individuals lack the ability to identify the type of waste they are producing.</li>\r\n    <li>Providing solution to categorize waste into 3 types: BioDegradable, NonBioDegradable and Hazardous based on the image of single garbage.</li>\r\n</ul>',
      input_output:
        "<p>Input: </p>\r\n<ul>\r\n<li>Image of single garbage.</li>\r\n</ul>\r\n<p>Output:&nbsp;</p>\r\n<ul>\r\n    <li>Garbage's category</li>\r\n</ul>",
      solution: {
        detail:
          '<p>For this problem, we preprocess an input image of garbage, then pass it through CNNs to label sub_types of garbage including: paper, cardboard, glass, metal, plastic, nilon, facemask, danger. Finally, we classify all above into 3 types: BioDegradable, Non-BioDegradable, Hazardous.</p>',
        img: '',
      },
      opportunity:
        'Suitable for households, schools, ... places with small trash bins or people who throw trash one by one.',
    },
    slug: 'garbage-classification',
  },
  {
    id: 3,
    name: 'ANIME STYLE TRANSFER',
    img: '/img/product/ANIME-STYLE-TRANSFER.webp',
    video: '/video/product/ANIME-STYLE-TRANSFER.webm',
    descriptions:
      'Our Anime Style Transfer solution seamlessly transforms regular portraits or photographs into captivating anime-style images.',
    content: {
      problem:
        '<ul>\r\n<li>Many individuals desire a creative and engaging method to transform their regular photographs or portraits into artistic anime-style images.</li>\r\n<li>However, the lack of accessible tools or techniques that accurately capture the essence of anime while maintaining the likeness of the original images presents a challenge.</li>\r\n<li>People seek an efficient, user-friendly, and reliable solution to convert their personal images into captivating anime renditions, enhancing their artistic expression and enjoyment.</li>\r\n</ul>',
      input_output:
        '<p>Input: </p>\r\n<ul>\r\n<li>Human Image: preferably portraits or photographs of individuals..&nbsp;</li>\r\n<li>Prompts and Negative Prompts: guide the style and characteristics of the generated anime image. Negative prompts help avoid unwanted changes.</li>\r\n</ul>\r\n<p>Output:&nbsp;</p>\r\n<ul>\r\n<li>Anime-Style Image: The anime-style image reflect the requested anime characteristics influenced by the provided prompts.</li>\r\n</ul>',
      solution: {
        detail:
          '<ul>\r\n<li>Stable Diffusion for Image Conversion:<ul>\r\n<li>Objective: Utilizing Stable Diffusion techniques to transform human images into anime-style renditions.</li>\r\n<li>Functionality: The algorithm progressively refines the input image, iteratively modifying it while preserving essential details to achieve a stylized anime representation.</li>\r\n</ul>\r\n</li>\r\n<li>LoRA for Prompt-Based Customization:<ul>\r\n<li>Objective: Enable user influence over the generated output by incorporating prompts and negative prompts.</li>\r\n<li>Functionality: LoRA allows users to specify prompts, phrases, or themes that influence the style and attributes of the generated anime-style image. Negative prompts help control undesirable changes.</li>\r\n</ul>\r\n</li>\r\n</ul>',
        img: '',
      },
      opportunity:
        'Offers a platform for anime enthusiasts and content creators to generate animestyle representations, potentially impacting gaming, animation, and digital content creation...',
    },
    slug: 'anime-style-transfer',
  },
]

const AISection = () => {
  return (
    <section className="bg-semiWhite" id="ai-service">
      {/* ===== Container ==== */}
      <div className="">
        <h2 className="text-black text-center font-bold text-3xl uppercase pt-[40px] mb-[40px]">
          01 Artificial Intelligence
        </h2>
        {/* ===== Content ==== */}
        <div className="flex-row justify-center items-center">
          <div className="flex justify-center items-center">
            <p className="text-[#636e80] max-w-[740px] text-center leading-6">
              With 20+ engineers, our AI team offers comprehensive consulting
              and development services. We specialize in Data Science, Deep
              Learning, and Machine Learning. Our expertise extends to BPO data
              labeling for enhanced accuracy and cost efficiency. Proficient in
              Java, Python, Lisp, Prolog, we excel in auto-training and
              re-training models, with a track record of building projects from
              the ground up.
            </p>
          </div>
          {/* ===== List AI Product ==== */}
          <div className="flex justify-around items-center">
            {projects.map((item) => (
              <ProductCard key={item.id} data={item} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default AISection
