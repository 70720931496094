import { FC } from 'react'

interface Props {
  width: number
  height: number
}

const IconList: FC<Props> = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24.8 18.92"
    >
      <path
        d="M23.8,9.46H1m22.8,8.46H1M23.8,1H1"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  )
}

export default IconList
