import { FC } from 'react'

interface Props {
  width: number
  height: number
}

const IconFile: FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1449 4.91675L11.2277 1.21085C10.8111 0.816669 10.2657 0.599609 9.69219 0.599609H2.23438C1.00234 0.599609 0 1.60195 0 2.83398V19.1652C0 20.3973 1.00234 21.3996 2.23438 21.3996H13.6094C14.8414 21.3996 15.8437 20.3973 15.8437 19.1652V6.53988C15.8437 5.92851 15.589 5.33689 15.1449 4.91675ZM13.9612 5.47461H10.9281C10.8161 5.47461 10.725 5.38349 10.725 5.27148V2.41295L13.9612 5.47461ZM13.6094 20.1809H2.23438C1.67436 20.1809 1.21875 19.7253 1.21875 19.1652V2.83398C1.21875 2.27397 1.67436 1.81836 2.23438 1.81836H9.50625V5.27148C9.50625 6.05551 10.1441 6.69336 10.9281 6.69336H14.625V19.1652C14.625 19.7253 14.1694 20.1809 13.6094 20.1809Z"
        fill="#636E80"
      />
      <path
        d="M12.2686 8.72461H3.33105C2.99452 8.72461 2.72168 8.99745 2.72168 9.33398C2.72168 9.67052 2.99452 9.94336 3.33105 9.94336H12.2686C12.6051 9.94336 12.8779 9.67052 12.8779 9.33398C12.8779 8.99745 12.6051 8.72461 12.2686 8.72461Z"
        fill="#636E80"
      />
      <path
        d="M12.2686 11.9746H3.33105C2.99452 11.9746 2.72168 12.2474 2.72168 12.584C2.72168 12.9205 2.99452 13.1934 3.33105 13.1934H12.2686C12.6051 13.1934 12.8779 12.9205 12.8779 12.584C12.8779 12.2474 12.6051 11.9746 12.2686 11.9746Z"
        fill="#636E80"
      />
      <path
        d="M6.2853 15.2246H3.33105C2.99452 15.2246 2.72168 15.4974 2.72168 15.834C2.72168 16.1705 2.99452 16.4434 3.33105 16.4434H6.2853C6.62184 16.4434 6.89468 16.1705 6.89468 15.834C6.89468 15.4974 6.62184 15.2246 6.2853 15.2246Z"
        fill="#636E80"
      />
    </svg>
  )
}

export default IconFile
