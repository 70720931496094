import './style.scss'

import React from 'react'

import Layout from '@/components/Common/Layout/MainLayout/Layout'
import LogoBapKYC from '@/components/Icons/LogoBapKYC'

import item01 from '../../../../../assets/img/bkyc/bkyc_item1.png'
import item02 from '../../../../../assets/img/bkyc/bkyc_item2.png'
import item03 from '../../../../../assets/img/bkyc/bkyc_item3.png'
import item04 from '../../../../../assets/img/bkyc/bkyc_item4.png'
import banner from '../../../../../assets/img/bkyc/bkyc_video_thumb.png'
import { ProductInfomation } from '../Components/ProductInfomation'
import BKYCTryItOutForm from './BKYCTryItOut'

const projectInfo = {
  title: 'Why BAP.AI Face Recognition?',
  desc: `AI Face ID, powered by BAP.AI, is an advanced facial recognition technology designed to provide seamless and secure identity verification.
  Leveraging cutting-edge artificial intelligence algorithms,
  AI Face ID offers fast and accurate recognition capabilities,
  ensuring reliable authentication for a variety of applications.`,
  video_thumb: banner,
  features: [
    {
      title: 'Precise Facial Recognition',
      desc: `AI Face ID utilizes advanced facial recognition algorithms to accurately identify individuals in real-time,
      ensuring precise results and high efficiency.`,
      img: item01,
    },
    {
      title: 'Adaptive Learning',
      desc: `AI Face ID continuously learns and adapts to improve its accuracy,
      able to adjust to varying environmental conditions,
      appearance changes, and different facial expressions.`,
      img: item02,
    },
    {
      title: 'Security and Privacy',
      desc: 'AI Face ID prioritizes user privacy and data security, safeguarding sensitive facial data with robust encryption protocols and advanced privacy measures.',
      img: item03,
    },
    {
      title: 'Seamless Integration',
      desc: 'AI Face ID is designed for smooth integration into various systems and applications, providing flexible deployment options to enhance user experience and operational efficiency.',
      img: item04,
    },
  ],
}

const BKYC: React.FC = () => {
  return (
    <Layout>
      {/* --- hero-featured --- */}
      <section className="hero-featured">
        {/* --- featured__pattern --- */}
        <div className="absolute z-0 top-0 left-0 w-full h-full">
          <div className="hero-featured__overlay"></div>
          <div className="hero-featured__texture"></div>
          <div className="absolute bottom-0 left-0 bg-vecter1 bg-cover bg-no-repeat w-full h-full z-1 translate-x-[-50%]"></div>
          <div className="absolute bottom-0 right-0 bg-effect01 bg-cover bg-no-repeat w-[547px] h-[545px] z-1"></div>
          <div className="hero-featured__vector3"></div>
        </div>
        <div className="container px-8 md:py-32 mx-auto flex flex-wrap justify-evenly gap-4">
          <div className="hero-featured__main relative">
            {/* --- decor patttern -- */}
            <div className="absolute t-0 r-0 bg-mask_ellipse_1 bg-contain bg-no-repeat w-[200%] h-[200%] translate-x-[-30%] translate-y-[-20%] z-[-1]"></div>
            {/* --- main title --- */}
            <h1 className=" text-4xl md:text-6xl text-white font-bold md:mb-4 max-w-[470px] leading-[87px] md:leading-[87px]">
              AI Face Recognition
            </h1>
            <div className="hero-featured__btns">
              <a href="#" className="btn">
                Get Started
              </a>
              <a href="#" className="btn">
                Request A Demo
              </a>
            </div>
            <div className="flex justify-start mt-[120px]">
              <LogoBapKYC width={151} height={151} />
            </div>
          </div>
          <BKYCTryItOutForm />
        </div>
      </section>
      <ProductInfomation projectInfo={projectInfo} />
    </Layout>
  )
}

export default BKYC
