import { FC } from 'react'

interface Props {
  width: number
  height: number
}

const IconRecognition: FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7097 5.69044C15.4557 5.43652 15.0444 5.43652 14.7905 5.69044L1.79053 18.6904C1.53662 18.9443 1.53662 19.3556 1.79053 19.6096L4.39054 22.2096C4.51748 22.3365 4.6838 22.4 4.85013 22.4C5.01645 22.4 5.18273 22.3365 5.30971 22.2096L18.3097 9.20957C18.5636 8.95566 18.5636 8.54436 18.3097 8.29044L15.7097 5.69044ZM4.85008 20.8309L3.16925 19.15L12.0001 10.3192L13.681 12C13.681 12 4.85008 20.8309 4.85008 20.8309ZM14.6001 11.0809L12.9192 9.4L15.2501 7.06915L16.9309 8.75003L14.6001 11.0809Z"
        fill="#636E80"
      />
      <path
        d="M10.7001 6.79962C11.3501 5.49964 12.0001 4.84963 13.3001 4.19962C12.0001 3.5496 11.3501 2.89963 10.7001 1.59961C10.0501 2.89959 9.40004 3.5496 8.1001 4.19962C9.40012 4.84963 10.0501 5.49964 10.7001 6.79962Z"
        fill="#636E80"
      />
      <path
        d="M21.1003 10.6992C20.7753 11.3492 20.4502 11.6742 19.8003 11.9992C20.4503 12.3242 20.7753 12.6492 21.1003 13.2992C21.4253 12.6492 21.7503 12.3242 22.4003 11.9992C21.7503 11.6742 21.4253 11.3492 21.1003 10.6992Z"
        fill="#636E80"
      />
      <path
        d="M21.1002 3.5496C20.1252 3.06208 19.6377 2.57461 19.1502 1.59961C18.6627 2.57461 18.1751 3.06213 17.2002 3.5496C18.1752 4.03712 18.6627 4.5246 19.1502 5.4996C19.6377 4.5246 20.1252 4.03708 21.1002 3.5496Z"
        fill="#636E80"
      />
    </svg>
  )
}

export default IconRecognition
