import React from 'react'

export const DOCUMENT_TYPE_ID = {
  ID: 'ID',
  DRIVER_LICENSE: 'DRIVER_LICENSE',
  PASSPORT: 'PASSPORT',
}

interface Props {
  selectedOption?: string;
  onSelected?: React.FC;
}

const DocumentTypeSelectBox: React.FC<Props> = ({
  selectedOption,
  onSelected,
}) => {
  const handleOptionChange = (event) => {
    onSelected && onSelected(event.target.value)
  }

  return (
    <div className="flex gap-4">
      <label>
        <input
          type="radio"
          value={DOCUMENT_TYPE_ID.ID}
          checked={selectedOption === DOCUMENT_TYPE_ID.ID}
          onChange={handleOptionChange}
        />
        <span className="pl-1">ID</span>
      </label>
      <label>
        <input
          type="radio"
          value={DOCUMENT_TYPE_ID.DRIVER_LICENSE}
          checked={selectedOption === DOCUMENT_TYPE_ID.DRIVER_LICENSE}
          onChange={handleOptionChange}
        />
        <span className="pl-1">Driver’s license</span>
      </label>
      <label>
        <input
          type="radio"
          value={DOCUMENT_TYPE_ID.PASSPORT}
          checked={selectedOption === DOCUMENT_TYPE_ID.PASSPORT}
          onChange={handleOptionChange}
        />
        <span className="pl-1">Passport</span>
      </label>
    </div>
  )
}

export default DocumentTypeSelectBox
