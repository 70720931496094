import './style.scss'

import React, { useEffect, useRef, useState } from 'react'

import IconFile from '@/components/Icons/IconFile'
import IconLoading from '@/components/Icons/IconLoading'
import IconRecognition from '@/components/Icons/IconRecognition'
import IconRecord from '@/components/Icons/IconRecord'

import useAudioRecorder from '../../../../../hooks/useAudioRecorder'
import {
  getAudioSpeech2TextInfo,
  postAudioSpeech2Text,
} from '../../../../../services/api/speech2text.api'

const TryItOutForm: React.FC = () => {
  const {
    permission,
    recordingStatus,
    audio,
    getMicrophonePermission,
    startRecording,
    stopRecording,
    audioChunks,
    clearRecording,
  } = useAudioRecorder()

  useEffect(() => {
    getMicrophonePermission()
  }, [])

  const [sseUrl, setSSEUrl] = useState<string | null>(null)
  const [result, setResult] = useState<string | null>(null)
  const [status, setStatus] = useState<string | null>(null)
  const [requestId, setRequestId] = useState<string | null>(null)

  useEffect(() => {
    console.log(' useEffect sseUrl', sseUrl, new Date())
    if (!sseUrl) return
    const eventSource = new EventSource(sseUrl)

    eventSource.addEventListener('message', (event) => {
      console.log({ event })
    })

    eventSource.onmessage = function (event) {
      const newData = JSON.parse(event.data)
      console.log('SSE OnMessage', newData)
      setResult(newData.result)
      setStatus(newData.status)
    }

    eventSource.onerror = function (error) {
      console.error('EventSource failed:', error)
      eventSource.close()
    }

    // Cleanup when component unmounts
    return () => {
      eventSource.close()
    }
  }, [sseUrl])

  useEffect(() => {
    if (!requestId) return
    const interval = setInterval(() => {
      console.log('pull request start', requestId)
      getAudioSpeech2TextInfo(requestId)
        .then((response) => {
          if (
            response.data.status === 'completed' ||
            response.data.status === 'failed'
          ) {
            clearInterval(interval)
          }

          setResult(response.data.result)
          setStatus(response.data.status)
        })
        .catch((err) => {
          console.log(err)
        })
    }, 1000)
    return () => clearInterval(interval)
  }, [requestId])

  const handleAudioRecoding = () => {
    if (!permission) {
      getMicrophonePermission()
    } else if (recordingStatus !== 'recording') {
      setSelectedFile(null)
      startRecording()
    } else if (recordingStatus == 'recording') {
      stopRecording()
    }
  }

  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const inputFileRef = useRef<HTMLInputElement>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    clearRecording()

    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      setSelectedFile(file)
    }
  }

  const handleSubmitCompute = async () => {
    try {
      setResult(null)
      setStatus(null)
      setRequestId(null)
      console.log('handleSubmitCompute begin', audioChunks, selectedFile)
      if (!audioChunks && !selectedFile) return
      let response = null

      if (audioChunks) {
        const blob = new Blob(audioChunks, { type: 'application/octet-stream' })
        response = await postAudioSpeech2Text(blob)
      } else {
        response = await postAudioSpeech2Text(selectedFile)
      }

      if (response.data) {
        setResult(response.data.result)
        setStatus(response.data.status)
        setRequestId(response.data._id)
        setSSEUrl(response.data.sseUrl)
      }
    } catch (err) {
      console.log('handleSubmitCompute ERR', err)
    }
  }

  return (
    <div id="hero-featured__try-it-out-form">
      <label className="btn-widget">
        <IconFile width={16} height={24} />
        <input
          ref={inputFileRef}
          accept="audio/*"
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <span className="text-[#636e80]">Browse for file</span>
      </label>

      <span className="">Or</span>

      <button onClick={handleAudioRecoding}>
        <label className="btn-widget">
          <IconRecord
            width={24}
            height={24}
            color={recordingStatus == 'recording' ? '#ef4444' : '#636e80'}
          />
          <span
            className={
              recordingStatus == 'recording' ? 'text-red-500' : 'text-[#636e80]'
            }
          >
            {recordingStatus !== 'recording'
              ? 'Record from browser'
              : 'Click to stop recording'}{' '}
          </span>
        </label>
      </button>

      <span>Or</span>

      <button>
        <label className="btn-widget">
          <IconRecognition width={24} height={24} />
          <span className="text-[#636e80]">Realtime speech recognition</span>
        </label>
      </button>

      <div className="mt-3 w-full">
        {/* <div className="mb-1.5 truncate text-sm text-gray-500">
          Librispeech sample 2
        </div> */}
        {audio && <audio src={audio} controls />}
        {selectedFile && (
          <audio controls>
            <source
              src={URL.createObjectURL(selectedFile)}
              type={selectedFile.type}
            />
          </audio>
        )}
      </div>
      {/* --- Compute Button --- */}
      <div>
        <button
          className="flex items-center justify-center gap-1 bg-[#0057E2] text-white font-medium rounded-lg h-10 w-36 px-5 mt-2 transition-all hover:bg-blue-600 duration-300"
          type="submit"
          disabled={status === 'pending'}
          onClick={handleSubmitCompute}
        >
          Compute
          {status === 'pending' && <IconLoading />}
        </button>
      </div>

      <p className="w-full text-xs text-gray-400">
        {`Computation status: ${status || 'cached'}`}
      </p>
      {/* --- Result --- */}
      {result && (
        <p className="w-full  mt-4 bg-[#E2FFE9] text-[#21983E] p-[16px] font-normal leading-6 rounded-2xl border border-solid border-green219 ">
          {result}
        </p>
      )}
    </div>
  )
}

export default TryItOutForm
